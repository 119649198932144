import axios from "axios";
import store from "@/store"
import {redirectToLogin} from "@/store/auth-module";
import config from "@/config";

// Ensure the redirection to the login page in case the API returns a 401 status code
axios.interceptors.response.use((r) => r,
	(error) => {
		if (error.response && error.response.status === 401) {
			console.log("auto redirect to login page")
			redirectToLogin();
			if (store) {
				store.commit('auth/setNeedsAuthentication', true);
			}
		}
		return Promise.reject(error)
	}
)

axios.defaults.baseURL = config.api.baseUrl;

export default axios;

