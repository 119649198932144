<template>
  <v-card>
    <div v-if="error">
      <v-snackbar color="warning" top v-if="errorMessage" :value="true" :timeout="8000">{{ errorMessage }}</v-snackbar>
      <v-unknown-error-alert v-else/>
    </div>
    <v-btn icon @click="$emit('close')">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-card-title class="pt-0 text-break">Modifier le détail de la consultation</v-card-title>

    <v-card-text>
      <v-form>

        <booth-select v-show="evaluation.type !== 'ap'" :rules="[rules.mandatory]" v-model="form.selectedBooth" :student-id="evaluation.student.id"/>
        <v-text-field :disabled="userType !== 'evaluator'" outlined type="number" v-model="form.sessionReference" label="Référence de la consultation"
                      :rules="[rules.mandatory]"/>

        <v-card-subtitle class="pb-0 pl-0 pt-0">Genre du patient</v-card-subtitle>
        <v-radio-group class="mb-4" row v-model="form.patientGender" :rules="[rules.mandatory]">
              <v-radio label="Homme" value="M"/>
              <v-radio label="Femme" value="F"/>
              <v-radio label="Autre" value="O"/>
        </v-radio-group>

        <v-text-field outlined type="number" v-model="form.patientAge" label="Âge du patient"
                      :rules="[rules.mandatory, rules.ageRange]"/>

        <v-combobox outlined :items="patientProfessionList" v-model="form.patientProfession"
                    label="Profession du patient" :rules="[rules.mandatory]"/>
        <v-text-field outlined v-model="form.patientReasonForConsultation" label="Motif de consultation du patient"
                      :rules="[rules.mandatory]"/>

        <label class="caption">Heure de début de la consultation</label>
        <div class="d-flex justify-center">
          <v-time-picker v-model="form.startedAtTimeOnly" format="24hr"/>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn text color="primary" @click="save">Enregistrer</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import BoothSelect from "@/components/BoothSelect";
import VUnknownErrorAlert from "@/components/VUnknownErrorAlert";
import EvaluationBusiness from "@/business/EvaluationBusiness";
import {mapGetters} from "vuex";

export default {
  name: "SessionEditCard",
  components: {VUnknownErrorAlert, BoothSelect},
  props: ['evaluation'],
  data: () => ({
    error: false,
    errorMessage: null,
    loading: false,
    form: {
      selectedBooth: null,
      sessionReference: null,
      startedAtTimeOnly: null,
      patientAge: null,
      patientGender: null,
      patientProfession: null,
      patientReasonForConsultation: null
    },
    patientProfessionList: [],
    rules: {
      mandatory: (v) => (!!v) || 'Ce champ est requis',
      ageRange: (v) => (v >= 0 && v <= 120) || 'L\'âge saisi est invalide'
    },
  }),
  mounted() {
    this.prepareForm(this.evaluation);
    this.patientProfessionList = EvaluationBusiness.getPatientProfessionList();
  },
  watch: {
    evaluation: {
      deep: true,
      handler: function (v) {
        this.prepareForm(v);
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['userType']),
  },
  methods: {
    prepareForm(sessionObj) {
      this.form.sessionReference = sessionObj.reference;
      this.form.selectedBooth = sessionObj.booth;
      this.form.patientGender = sessionObj.metadata.patientGender;
      this.form.patientAge = sessionObj.metadata.patientAge;
      this.form.patientProfession = sessionObj.metadata.patientProfession;
      this.form.patientReasonForConsultation = sessionObj.metadata.patientReasonForConsultation;
      this.form.startedAtTimeOnly = this.$moment(sessionObj.startedAt).format("HH:mm:ss");
    },
    save() {
      if (this.form.selectedBooth && this.form.sessionReference !== "") {
        this.loading = true;
        this.error = false;
        const newStartedDate = this.$moment(this.evaluation.startedAt);
        newStartedDate.set('hour', this.form.startedAtTimeOnly.substr(0, 2));
        newStartedDate.set('minute', this.form.startedAtTimeOnly.substr(3, 2));
        newStartedDate.set('second', 0);
        console.log("new", newStartedDate.format());
        this.$http.put(`/v1/evaluations/${this.evaluation.id}`,
            {
              boothId: this.form.selectedBooth.id,
              reference: this.form.sessionReference,
              startedAt: newStartedDate.format(),
              metadata: { // Côté backend seuls les champs ci-après seront mis à jour (et rien d'autre ne sera écrasé)
                patientAge: this.form.patientAge,
                patientGender: this.form.patientGender,
                patientProfession: this.form.patientProfession,
                patientReasonForConsultation: this.form.patientReasonForConsultation
              }
            })
            .then(() => this.$emit('save'))
            .catch(e => {
              if (e.response && e.response.data && e.response.data.humanReadableError) {
                this.errorMessage = e.response.data.humanReadableError;
              } else this.errorMessage = null;
              this.error = this.$err(e);
            })
            .finally(() => this.loading = false)
      }
    }
  }
}
</script>

<style scoped>
.word-wrap {
  word-wrap: break-word;
}
</style>
