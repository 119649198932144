<template>
  <v-container class="mt-4 d-flex flex-column align-center">
    <v-btn v-if="userType==='student'" outlined color="primary" @click="$router.push('/student/me')">Accéder à l'espace étudiant</v-btn>
    <v-btn v-else-if="userType==='evaluator'" outlined color="primary" @click="$router.push('/eval')">Accéder à l'espace évaluateur</v-btn>
    <span v-else>Vous ne disposez pas des droits d'accès à cette application</span>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "HomeView",
  computed: {
    ...mapGetters('auth', ['userType'])
  }
}
</script>

<style scoped>

</style>
