import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import FindSessionView from '@/views/evaluators/FindSessionView.vue'
import CreateSessionView from '@/views/common/CreateSessionView.vue'
import StudentHomeView from "@/views/student/StudentHomeView";
import SessionView from "@/views/common/SessionView";
import store from '@/store'
import StudentEvaluationListView from "@/views/student/StudentEvaluationListView";
import StudentAveragesView from "@/views/common/StudentAveragesView";

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'HomeView',
		component: HomeView
	},
	{
		path: '/eval',
		name: 'FindSessionView',
		component: FindSessionView,
		props: true,
		meta: {
			roles: ['evaluator']
		}
	},
	{
		path: '/eval/create-session/student/:studentId',
		name: 'EvalWithStudentCreateSessionView',
		component: CreateSessionView,
		props: true,
		meta: {
			roles: ['evaluator']
		}
	},
	{
		path: '/eval/create-session',
		name: 'EvalCreateSessionView',
		props: true,
		component: CreateSessionView,
		meta: {
			roles: ['evaluator']
		}
	},
	{
		path: '/student/me/create-session',
		name: 'StudentCreateSessionView',
		component: CreateSessionView,
		meta: {
			roles: ['student']
		}
	},
	{
		path: '/student/me',
		name: 'StudentHomeView',
		component: StudentHomeView,
		meta: {
			roles: ['student']
		}
	},
	{
		path: '/student/me/evaluations',
		name: 'StudentEvaluationListView',
		component: StudentEvaluationListView,
		meta: {
			roles: ['student']
		}
	},
	{
		path: '/student/:userId/average-marks',
		name: 'StudentAveragesView',
		component: StudentAveragesView,
		props: true,
		meta: {
			roles: ['student', 'admin']
		}
	},
	{
		path: '/evaluations/:evaluationId',
		name: 'SessionView',
		component: SessionView,
		props: true,
		meta: {
			roles: ['student', 'evaluator', 'admin']
		}
	},
	{
		path: '*', // To redirect all unknown route to the home page
		redirect: '/'
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

// We use this hook to prevent unauthorized users to display pages they shouldn't be allowed to see. This is purely cosmetic, the actual security is still handled server side by the API.
router.beforeEach((to, from, next) => {
	if (store && Array.isArray(to.meta.roles)) {
		let roles = store.getters["auth/userRoles"];
		if (roles && Array.isArray(roles) && roles.length > 0) {
			let isAuthorized;
			if (roles.length === 1) {
				isAuthorized = to.meta.roles.includes(roles[0]);
			} else {
				// We check if the intersection between the two arrays (user role and page's authorized roles) has at least 1 element
				isAuthorized = roles.filter(v => to.meta.roles.includes(v)).length >= 1;
			}

			if (isAuthorized) { // Requested page is authorized to current role. We allow it.
				next();
			} else {
				console.error("Tentative d'acces a la page " + to.path + " accessible uniquement pour " + JSON.stringify(to.meta.roles) + " depuis un utilisateur en ayant les roles " + JSON.stringify(roles))
				next({path: '/', replace: true}); // We prevent access to unauthorized page, and redirect to the home page.
			}
		} else next(); // We don't have a user type, we can't check the authorization. We allow it.
	} else next(); // We either failed to get a Vuex store instance, or the route doesn't have role restriction. We allow it.
})

export default router
