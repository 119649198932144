<template>
  <v-container>
    <h2 class="ma-1">Espace étudiant</h2>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Consultation récentes</v-card-title>
          <student-session-list scope="recent" show-creation-link :student-id="user.id"
                                @session-selected="sessionSelected"
                                @create-session="createSession" show-type show-signature-status/>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Tableau de bord</v-card-title>

          <v-list>
            <v-list-item-group>
              <v-list-item @click="$router.push('/student/me/evaluations')">
                <v-list-item-avatar><v-icon>list</v-icon></v-list-item-avatar>
                <v-list-item-title>Liste des consultations précédentes</v-list-item-title>
                <v-list-item-action><v-icon>mdi-chevron-right</v-icon></v-list-item-action>
              </v-list-item>
              <v-list-item @click="$router.push('/student/me/average-marks')">
                <v-list-item-avatar><v-icon>mdi-chart-line</v-icon></v-list-item-avatar>
                <v-list-item-title>Moyennes des évaluations</v-list-item-title>
                <v-list-item-action><v-icon>mdi-chevron-right</v-icon></v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Apprentissage progressif</v-card-title>
          <student-session-list scope="student-classmates" :show-creation-link="false" :student-id="user.id" @session-selected="sessionSelected"
              show-student-name auto-refresh show-booth-label show-type show-signature-status/>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StudentSessionList from "@/components/StudentSessionList";
import {mapState} from "vuex";

export default {
  name: "StudentHomeView",
  components: {StudentSessionList},
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    sessionSelected(s) {
      this.$router.push('/evaluations/' + s.id)
    },
    createSession() {
      this.$router.push('/student/me/create-session')
    }
  }
}
</script>

<style scoped>

</style>
