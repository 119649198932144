<template>
  <ul>
    <v-skeleton-loader v-if="loading" type="list-item-two-line"/>
    <li v-if="error" class="error--text">Une erreur est survenue lors du chargement du détail de cette consultation</li>
    <li v-else><b>Patient : <v-icon small>{{iconForGender}}</v-icon> {{session.metadata['patientAge']}} ans</b>, {{session.metadata['patientProfession']}}, venu pour "{{session.metadata['patientReasonForConsultation']}}"</li>
    <li v-for="mark in marksForEvaluationId" :key="mark.id">{{criteriaForId(session, mark.criteriaId).label}} : {{mark.value}}/100</li>
    <li v-if="marksForEvaluationId.length === 0" class="font-italic">Aucune note n'a été saisie pour cette consultation</li>
  </ul>
</template>

<script>
import EvaluationBusiness from "@/business/EvaluationBusiness";
import {mapState} from "vuex";

export default {
  name: "SignatureDialogSessionSummary",
  props: {
    session: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    sessionWithMarks: null,
    loading: false,
    error: false,
  }),
  mounted() {
    if (this.session.marks) {
      this.sessionWithMarks = this.session;
    } else {
      this.fetchEvaluation();
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    isDataAvailable() {
      return this.sessionWithMarks !== null;
    },
    marksForEvaluationId() {
      return this.sessionWithMarks ? this.sessionWithMarks.marks : [];
    },
    processedCriteriaMap() {
      return this.sessionWithMarks ? EvaluationBusiness.getFlattenedCriteriaSetWithLabels(this.sessionWithMarks.criteriaSet) : null;
    },
    iconForGender() {
      switch (this.session.metadata['patientGender']) {
        case 'M': return 'male';
        case 'F': return 'female';
        default: return '';
      }
    }
  },
  methods: {
    criteriaForId(evaluation, criteriaId) {
      return this.processedCriteriaMap[criteriaId];
    },
    fetchEvaluation() {
      this.error = false;
      this.loading = true;
      this.$http.get(`/v1/evaluations/${this.session.id}`)
          .then(response => this.sessionWithMarks = response.data)
          .catch(e => this.$err(e))
          .finally(() => this.loading = false)
    }
  }
}
</script>


<style scoped>

</style>
