import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import config from './config'
import axios from './plugins/axios'
import VueTimers from 'vue-timers'
import newRelicErrorHandler from "@yodatech/html-webpack-new-relic-plugin/lib/ErrorHandler"
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import './plugins/vue-moment' // Sets up and adds the plugin to the Vue instance


Vue.config.productionTip = false

Vue.use(VueTimers)

Vue.prototype.$err = (e) => { try { newRelicErrorHandler(e) } catch (e2) { console.error(e2) } return (e && e.response && e.response.status === 401) ? false : e }
Vue.prototype.$config = config;
Vue.prototype.$http = axios;

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
