<template>
  <div>
    <v-tooltip left :disabled="!showTooltip">
      <!-- We enable the tooltip if the button is disabled (to explain why it is disabled), or when the comment is readonly -->
      <template v-slot:activator="{on}">
        <v-btn icon x-small @click="buttonHasBeenClicked" v-on="on">
          <v-icon v-if="value" color="primary">mdi-comment</v-icon>
          <v-icon v-else :color="disabled ? 'secondary lighten-3' : 'secondary'">mdi-comment-outline</v-icon>
        </v-btn>
      </template>
      <span v-if="!readonly">La compétence doit d'abord être notée</span>
      <span v-else>
        <span v-if="value">{{ shortenedValue }}</span>
        <span v-else>Aucun commentaire sur cette compétence</span>
      </span>
    </v-tooltip>
    <v-dialog v-model="showDialog" max-width="500">
      <v-card>
        <v-card-title>Commentaire</v-card-title>
        <v-card-subtitle class="e-word-break-keep">{{ criteria.academicDescription }}</v-card-subtitle>
        <v-card-text>
          <v-textarea v-model="curVal" :readonly="readonly" outlined hide-details/>
        </v-card-text>
        <v-card-actions v-if="!readonly">
          <v-btn text color="secondary" @click="cancel">Annuler</v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="save">Enregistrer</v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer/>
          <v-btn text color="secondary" @click="showDialog=false">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

const COMMENT_TOOLTIP_MAX_LENGTH = 50;

export default {
  name: "CriteriaCommentButton",
  props: ['criteria', 'value', 'readonly', 'showAcademicDescription', 'disabled'],
  data: () => ({
    showDialog: false,
    curVal: null
  }),
  mounted() {
    this.curVal = this.value;
  },
  watch: {
    value(v) {
      this.curVal = v;
    }
  },
  computed: {
    showTooltip() {
      return this.disabled; //  || (this.readonly); // XXX Re-add this line to show a preview of the comment as a tooltip when possible. Disable for new since it's a bit annoying on touch screens
    },
    shortenedValue() {
      return this.value ? (this.value.length < COMMENT_TOOLTIP_MAX_LENGTH ? this.value : this.value.substr(0, COMMENT_TOOLTIP_MAX_LENGTH - 1) + "…") : "";
    }
  },
  methods: {
    buttonHasBeenClicked() {
      // If button is disabled, OR if comment is readonly and empty, we don't bring up the dialog
      if (!this.disabled && !(this.readonly && (!this.value || this.value.length === 0))) {
        this.showDialog = !this.showDialog;
      }
    },
    cancel() {
      this.curVal = this.value;
      this.showDialog = false;
    },
    save() {
      if (this.curVal !== this.value) {
        this.$emit('input', this.curVal, this.criteria)
      }
      this.showDialog = false;
    }
  }
}
</script>

<style scoped>

.e-word-break-keep {
  word-break: keep-all !important;
}

</style>
