<template>
  <div class="signature-pill-container grey lighten-4" v-bind:class="{'success lighten-3': signatureStatus.hasEvaluatorSigned && signatureStatus.hasStudentSigned, 'warning lighten-3': needsCurrentUserSignature}">
    <v-icon small :alt="signatureStatus.hasEvaluatorSigned ? 'L’evaluateur a signé' : 'L’evaluateur n’a pas signé'" :color="signatureStatus.hasEvaluatorSigned ? 'success darken-2' :'error'">mdi-account-tie</v-icon>
    <v-icon small :alt="signatureStatus.hasEvaluatorSigned ? 'L’étudiant a signé' : 'L’étudiant n’a pas signé'" :color="signatureStatus.hasStudentSigned ? 'success darken-2' :'error'" >mdi-account-school</v-icon>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SignatureStatusBadge",
  props: {
    signatureStatus: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('auth', ['isEvaluator', 'isStudent']),
    needsCurrentUserSignature() {
      return ((this.isEvaluator && !this.signatureStatus.hasEvaluatorSigned) || (this.isStudent && !this.signatureStatus.hasStudentSigned)) && this.signatureStatus.hasBeenFullySigned === false;
    }
  }
}
</script>


<style scoped>
.signature-pill-container {
  width: 2.7em;
  height: 1.3em;
  border-radius: 0.65em;
  display: flex;
  justify-content: center;
}

</style>
