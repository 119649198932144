<template>
  <v-card>
    <v-btn icon @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
    <v-card-title class="pt-0 text-break">Commentaires sur la consultation</v-card-title>
    <v-card-text>
      <span v-if="comments.length===0">Aucun commentaire</span>
      <session-comment-row @save="$emit('save')" v-for="(c, i) of comments" :key="i" :comment="c" :evaluation="evaluation" :readonly="readonly" v-bind:class="{'pt-12':i>0}"/>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapState} from "vuex";
import SessionCommentRow from "@/components/SessionCommentRow";

export default {
  name: "SessionCommentCard",
  components: {SessionCommentRow},
  props: ['evaluation', 'readonly'],
  computed: {
    ...mapState('auth', ['user']),
    comments() {
      let currentUserHasAlreadyPosted = false;
      let result = [];
      for (const com of this.evaluation.globalComments) {
        currentUserHasAlreadyPosted = currentUserHasAlreadyPosted || com.evaluator.id === this.user.id;
        result.push(com);
      }
      if (!currentUserHasAlreadyPosted && !this.readonly) {
        result.push({content: null, evaluator: {...this.user}})
      }
      return result;
    }
  }
}
</script>

<style scoped>

</style>
