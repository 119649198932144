<template>
  <v-container v-bind:class="{'pa-0': $route.query.hasOwnProperty('embedded')}">
    <v-unknown-error-alert v-if="errorFetchingAverages || errorFetchingPeriods"/>
    <v-btn v-if="!$route.query.hasOwnProperty('embedded')" text @click="$router.push('/student/me')">
      <v-icon>mdi-chevron-left</v-icon>
      Retour
    </v-btn>
    <v-card>
      <v-card-title>
        <div class="d-flex flex-row" style="width: 100%">
          <div class="flex-grow-1 e-text-wrap-word">
        Moyennes des évaluations
          </div>

        <div class="flex-grow-0 align-end">
          <session-description-type-select v-model="showAcademicDesc" selected-color="primary lighten-1"/>
        </div>

        </div>
      </v-card-title>
      <v-progress-linear indeterminate v-if="loadingPeriods"/>
      <v-tabs v-else v-model="selectedPeriodIndex" show-arrows grow>
        <v-tab v-for="(p,i) in periods" :key="i">{{p.label.substr(0,4)}} — Période {{ p.number }}</v-tab>
      </v-tabs>
      <v-card-text v-if="!loadingAverages">
        <span class="font-weight-bold">{{ evaluationsStatus.totalCount }}</span> consultations<span v-if="evaluationsStatus.totalCount>1">s</span> réalisée<span
          v-if="evaluationsStatus.totalCount>1">s</span> sur cette période, dont <span class="font-weight-bold">{{ evaluationsStatus.markedCount }}</span> notée<span
          v-if="evaluationsStatus.markedCount>1">s</span>
      </v-card-text>
      <v-skeleton-loader type="card-heading@2, image" v-if="loadingAverages"/>
      <criteria-set v-else
                    :show-academic-descriptions="showAcademicDesc"
                    :criteria-set="criteriaSet"
                    :existing-marks="studentAverages"
                    :loading-criteria-ids="[]"
                    :readonly="true"
                    :hide-comments="true"
                    :average-marks="schoolyearAverages"
                    :average-marks-count="evaluationsStatus.countPerCriteriaId"
                    v-model="openedCriteriaCategory"/>
    </v-card>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import VUnknownErrorAlert from "@/components/VUnknownErrorAlert";
import CriteriaSet from "@/components/CriteriaSet";
import SessionDescriptionTypeSelect from "@/views/common/SessionDescriptionTypeSelect";

export default {
  name: "StudentAveragesView",
  props: ['userId'],
  components: {SessionDescriptionTypeSelect, CriteriaSet, VUnknownErrorAlert},
  data: () => ({
    loadingPeriods: false,
    errorFetchingPeriods: false,
    loadingAverages: false,
    errorFetchingAverages: false,

    selectedPeriodIndex: 0,
    periods: [],

    showAcademicDesc: false,

    studentAverages: null,
    evaluationsStatus: {countPerCriteriaId: null, markedCount: null, totalCount: null},
    schoolyearAverages: null,
    criteriaSet: null,

    openedCriteriaCategory: null
  }),
  computed: {
    ...mapState('auth', ['user']),
    effectiveUserId() {
      if (!this.userId || this.userId === 'me' || !Number.isInteger(parseInt(this.userId))) {
        return this.user.id
      } else return parseInt(this.userId);
    }
  },
  mounted() {
    this.fetchPeriods();
  },
  watch: {
    selectedPeriodIndex(v) {
      this.fetchAverages(this.periods[v]);
    },
  },
  methods: {
    fetchPeriods() {
      this.loadingPeriods = true;
      this.errorFetchingPeriods = false;
      this.$http.get(`/v1/students/${this.effectiveUserId}/semesters`)
          .then((r) => {
            this.periods = r.data;
            this.fetchAverages(this.periods[0])
          })
          .catch(e => this.errorFetchingPeriods = this.$err(e))
          .finally(() => this.loadingPeriods = false);
    },
    fetchAverages(period) {
      this.loadingAverages = true;
      this.errorFetchingAverages = false;
      this.fetchAveragesAndOtherData(period)
          .then(r => {
            this.studentAverages = r.studentAverages;
            this.evaluationsStatus = r.evaluationsStatus;
            this.criteriaSet = r.criteriaSet;
            this.schoolyearAverages = r.schoolyearAverages;
          })
          .catch(e => this.errorFetchingAverages = this.$err(e))
          .finally(() => this.loadingAverages = false)
    },
    async fetchAveragesAndOtherData(period) {
      return {
        studentAverages: (await this.$http.get(`/v1/students/${this.effectiveUserId}/semesters/${period.id}/average-marks`)).data,
        evaluationsStatus: (await this.$http.get(`/v1/students/${this.effectiveUserId}/semesters/${period.id}/evaluations-count`)).data,
        criteriaSet: (await this.$http.get(`/v1/schoolyears/${period.schoolyearId}/semesters/${period.id}/criteria-set`)).data,
        schoolyearAverages: (await this.$http.get(`/v1/schoolyears/${period.schoolyearId}/semesters/${period.id}/average-marks`)).data
      };
    }
  }
}
</script>

<style scoped>
.e-text-wrap-word {
  word-break: break-word;
}
</style>
