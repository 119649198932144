<template>
  <v-form ref="form" @submit.prevent="submit">
    <!-- We only show the student selection input if no 'studentId' props has been given -->
    <student-select :outlined="true" v-if="!studentId" :rules="[rules.mandatory]" @error="onError" v-model="selectedStudent"/>


    <v-btn-toggle class="y-consultation-type-selector mb-4 mt-4" :color="colorForType" shaped v-model="form.type" v-show="userType !== 'evaluator' || !user.evaluatorDetail.restrictedBooth">
      <v-btn :disabled="!isStudentSelected" value="internal">Interne</v-btn>
      <v-btn :disabled="!isStudentSelected" value="external">Externe</v-btn>
      <v-btn :disabled="!isStudentSelected" value="ap">AP</v-btn>
    </v-btn-toggle>

    <div class="y-retractable-form" v-bind:class="{'y-form-opened': form.type}">
      <booth-select v-if="userType !== 'evaluator' || !user.evaluatorDetail.restrictedBooth" v-show="form.type !== 'ap'" :rules="form.type === 'ap' ? [] : [rules.mandatory]" v-model="selectedBooth" :student-id="form.studentId" ref="boothSelect" :type="form.type"/>

      <v-text-field outlined type="number" v-model="form.reference" label="Référence de la consultation" :rules="[rules.mandatory]" v-show="userType === 'evaluator'" />

      <v-card-subtitle class="pb-0 pl-0 pt-0">Genre du patient</v-card-subtitle>
      <v-radio-group class="mb-6 mt-2" v-model="form.metadata.patientGender" row :rules="[rules.mandatory]">
          <v-radio label="Homme" value="M"/>
          <v-radio label="Femme" value="F"/>
          <v-radio label="Autre" value="O"/>
      </v-radio-group>

      <v-text-field prepend-icon="" outlined type="number" v-model="form.metadata.patientAge" label="Âge du patient"
                    :rules="[rules.mandatory, rules.ageRange]"/>

      <v-combobox outlined :items="patientProfessionList" v-model="form.metadata.patientProfession"
                  label="Profession du patient" :rules="[rules.mandatory]"/>
      <v-text-field outlined v-model="form.metadata.patientReasonForConsultation" label="Motif de consultation du patient"
                    :rules="[rules.mandatory]"/>
    </div>

  </v-form>
</template>

<script>

import StudentSelect from "@/components/StudentSelect";
import BoothSelect from "@/components/BoothSelect";
import EvaluationBusiness from "@/business/EvaluationBusiness";
import {mapGetters, mapState} from "vuex";

const defaultForm = {
  type: null,
  studentId: null,
  boothId: null,
  reference: null,
  metadata: {
    patientAge: null,
    patientGender: null,
    patientProfession: null,
    patientReasonForConsultation: null
  }
}

export default {
  name: "CreateSessionForm",
  components: {BoothSelect, StudentSelect},
  props: ['studentId'],
  data: () => ({
    form: Object.assign({}, defaultForm),
    selectedBooth: null,
    selectedStudent: null,
    rules: {
      mandatory: (v) => (!!v) || 'Ce champ est requis',
      ageRange: (v) => (v >= 0 && v <= 120) || 'L\'âge saisi est invalide'
    },
    patientProfessionList: [],
    suggestedReferences: {},
    init: false // to avoid sending "input" event during init. Is set to true at the end of mounted()
  }),
  watch: {
    form: {
      deep: true,
      handler(f) {
        if (this.init) {
          this.$emit('input', f);
        }
      }
    },
    selectedBooth(v) {
      this.form.boothId = v ? v.id : null;
      this.applyReferenceSuggestion();
    },
    selectedStudent(v) {
      this.form.studentId = v ? v.id : null;
      this.fetchReferenceSuggestions(v.id);
    },
    studentId(v) {
      this.form.studentId = v;
      this.fetchReferenceSuggestions(v);
    },
    "form.type"(v) {
      if (v === 'ap') this.form.boothId = null;
      this.applyReferenceSuggestion();
    }
  },
  mounted() {
    // Given props are used in the final form object, corresponding inputs will be hidden
    // No need to force preselected student in case the auth user is a student, the parent component should manage this
    this.form.studentId = this.studentId;
    this.fetchReferenceSuggestions(this.studentId);
    this.patientProfessionList = EvaluationBusiness.getPatientProfessionList();
    if (this.userType === 'evaluator' && this.user.evaluatorDetail.restrictedBooth) {
      this.form.boothId = this.user.evaluatorDetail.restrictedBooth.id;
      this.form.type = 'external';
    }
    this.init = true;
  },
  computed: {
    ...mapGetters('auth', ['userType']),
    ...mapState('auth', ['user']),
    isStudentSelected() {
      return this.form.studentId;
    },
    availableBooths() {
      return this.$store.state.data.booths.filter(b => b.isExternal === (this.form.type === 'external'));
    },
    colorForType() {
      switch (this.form.type) {
        case 'internal':
          return 'cyan accent-4'
        case 'external':
          return 'purple accent-2'
        case 'ap':
          return 'green accent-4'
        default:
          return null
      }
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit('submit', this.form);
      }
    },
    validate() {
      let validate = this.$refs.form.validate(); // On l'écrit sur deux lignes pour déclencher la validation même si aucun étudiant n'était sélectionné
      return this.isStudentSelected && validate;
    },
    onError(e) {
      this.$emit('error', e);
    },
    fetchReferenceSuggestions(studentId) {
      if (studentId) {
        this.$http.get(`/v1/students/${studentId}/next-evaluation-references-suggestion`)
            .then(r=>{
              this.suggestedReferences = r.data
              this.applyReferenceSuggestion();
            })
            .catch(e=>this.$err(e)); // no particular error handling here, this is not a deal-breaker
      }
    },
    applyReferenceSuggestion() {
      if (this.form.type) {
        this.form.reference = this.suggestedReferences[this.form.type];
      }
    }
  }
}
</script>

<style scoped>
.y-consultation-type-selector {
  width: 100%;
}

.y-consultation-type-selector > button {
  width: 33.33% !important;
}

.y-retractable-form {
  max-height: 0;
  overflow: hidden;
  padding-top: 0;
  opacity: 0;
  transition: max-height 0.3s ease-in-out,  padding 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.y-retractable-form.y-form-opened {
  padding-top: 20px;
  max-height: 1000px;
  opacity: 1;
}
</style>
