<template>
  <div class="d-flex flex-row align-center">
    <v-icon :color="!v ? colorOn : ''">
      mdi-coffee
    </v-icon>
    <v-switch class="pt-0 mt-0" v-model="v" color="gray" hide-details/>
    <v-icon :color="v ? colorOn : ''">
      mdi-school
    </v-icon>
  </div>
</template>
<script>
export default {
  name: 'SessionDescriptionTypeSelect',
  props: ['value', 'selectedColor'],
  data: ()=>({v: null}),
  computed: {
    colorOn() {
      return this.selectedColor ? this.selectedColor : 'accent lighten-1';
    }
  },
  mounted() {
    this.v = this.value === true;
  },
  watch: {
    v(v) {
      this.$emit('input', v);
    }
  }
}
</script>

<style scoped>

</style>
