<template>
  <div>
    <v-unknown-error-alert v-if="error"/>
    <v-textarea auto-grow placeholder="Ajouter un commentaire..." rows="1" hide-details outlined :readonly="isReadonly"
                v-model="commentContent"/>
    <div class="d-flex flex-row">
      <span class="caption">par {{ commenterName }}</span>
      <v-spacer/>
      <v-btn text v-if="!isReadonly" @click="saveComment" color="primary"
             :disabled="comment.content === commentContent || commentContent === ''">Enregistrer
      </v-btn>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import VUnknownErrorAlert from "@/components/VUnknownErrorAlert";

export default {
  name: "SessionCommentRow",
  components: {VUnknownErrorAlert},
  props: ['comment', 'evaluation', 'readonly'],
  data: () => ({
    error: false,
    loading: false,
    commentContent: null
  }),
  mounted() {
    this.commentContent = this.comment.content;
  },
  watch: {
    comment: {
      deep: true,
      handler: function (v) {
        this.commentContent = v.content;
      }
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    isReadonly() {
      return !this.byCurrentUser || !this.evaluation.isWritable || this.readonly;
    },
    byCurrentUser() {
      return this.user.id === this.comment.evaluator.id;
    },
    commenterName() {
      return this.byCurrentUser ? 'vous' : this.comment.evaluator.fullname;
    }
  },
  methods: {
    saveComment() {
      this.error = false;
      this.loading = true;
      this.$http.put(`/v1/evaluations/${this.evaluation.id}/comments`, {comment: this.commentContent})
          .then(() => this.$emit('save'))
          .catch((e) => this.error = this.$err(e))
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
