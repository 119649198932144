import axios from 'axios';
import config from '@/config'

// see plugins/axios.js to see how automatic redirect to login page is handled
function hasRole(user, role) {
	if (!user || !Array.isArray(user.roles)) return null;
	return (user.roles.indexOf(role) >= 0);
}

export default {
	state: () => ({
		sessionToken: null,
		isValidatingAuthentication: false,
		isAuthenticated: false,
		needsAuthentication: false,
		tooManyRequestsError: false,
		errorOccurred: false,
		user: {},
	}),

	mutations: {
		setAuthenticated(state, isAuthenticated) {
			state.isAuthenticated = isAuthenticated;
		},
		setSessionToken(state, sessionToken) {
			state.sessionToken = sessionToken;
		},
		setValidatingAuthentication(state, isValidatingAuth) {
			state.isValidatingAuthentication = isValidatingAuth;
		},
		setUser(state, user) {
			state.user = user;
		},
		setError(state, error) {
			state.errorOccurred = error;
		},
		setNeedsAuthentication(state, needsAuthentication) {
			state.needsAuthentication = needsAuthentication;
		},
		setTooManyRequestsError(state, tooManyRequestsError) {
			state.tooManyRequestsError = tooManyRequestsError;
		}
	},
	getters: {
		userType: (state) => {
			if (!state.user || !Array.isArray(state.user.roles)) return null;
			if (state.user.roles.indexOf('student') >= 0) return 'student';
			if (state.user.roles.indexOf('evaluator') >= 0) return 'evaluator';
			if (state.user.roles.indexOf('admin') >= 0) return 'admin';
			else return 'unauthorized';
		},
		userRoles: (state) => {
			if (!state.user || !Array.isArray(state.user.roles)) return [];
			else return state.user.roles;
		},
		isStudent: (state) => {
			return hasRole(state.user, 'student');
		},
		isEvaluator: (state) => {
			return hasRole(state.user, 'evaluator');
		},
		isAdmin: (state) => {
			return hasRole(state.user, 'admin');
		},
	},
	actions: {
		checkAuthentication({commit}, forcedToken = null) {
			console.log("checkAuthentication, forcedToken: ", forcedToken, "cookie: ", getCookie(config.auth.sessionTokenCookieName), "cookie name: ", config.auth.sessionTokenCookieName, "cookie domain: ", config.auth.sessionTokenCookieDomain)
			const token = forcedToken ? forcedToken : getCookie(config.auth.sessionTokenCookieName)

			if (token) {
				// We found a session token, so we call the API to get the current user detail, and therefore check the validity of the session token
				commit("setValidatingAuthentication", true);
				commit("setError", false);
				commit('setNeedsAuthentication', false);
				axios.defaults.headers.common[config.auth.sessionTokenHeaderName] = token;

				axios.get('/v1/whoami')
					.then((r) => {
						commit("setUser", r.data)
						commit("setSessionToken", token)
						commit("setAuthenticated", true)
					})
					.catch((e) => {
						console.log("error while checking authentication: ", e)
						commit("setAuthenticated", false);
						if (e.response && e.response.status === 401) {
							commit('setNeedsAuthentication', true);
							redirectToLogin();
						}
						if (e.response && e.response.status === 429) {
							commit('setTooManyRequestsError', true);
						} else {
							// If the API didn't respond a 401, it may indicate an error occurred (unreachable API, server error, etc.)
							commit('setError', true);
						}
					})
					.finally(() => commit("setValidatingAuthentication", false))
			} else {
				// No session token found, we answer right away
				commit("setAuthenticated", false)
				commit('setNeedsAuthentication', true);
				console.log("no session token found")
				redirectToLogin();
			}


		}
	},
	namespaced: true
}

export function redirectToLogin() {
	document.cookie = config.auth.sessionTokenCookieName + "=; path=/; max-age=-1; domain=" + config.auth.sessionTokenCookieDomain;
	if (config.auth.mockRedirection) {
		console.log("should redirect to login page...")
	} else {
		console.log("redirect to login page", new Error().stack);
		window.location = config.auth.loginUrl + '?from=' + window.location;
	}
}

export function disconnect() {
	document.cookie = config.auth.sessionTokenCookieName + "=; path=/; max-age=-1; domain=" + config.auth.sessionTokenCookieDomain;
	document.location = config.auth.logoutUrl;
}

// https://www.tutorialrepublic.com/javascript-tutorial/javascript-cookies.php
function getCookie(name) {
	// Split cookie string and get all individual name=value pairs in an array
	let cookieArr = document.cookie.split(";");

	// Loop through the array elements
	for (let i = 0; i < cookieArr.length; i++) {
		let cookiePair = cookieArr[i].split("=");

		/* Removing whitespace at the beginning of the cookie name
		and compare it with the given string */
		if (name === cookiePair[0].trim()) {
			// Decode the cookie value and return
			return decodeURIComponent(cookiePair[1]);
		}
	}

	// Return null if not found
	return null;
}
