var merge = require('webpack-merge')
var prodEnv = require('./prod.env')

module.exports = merge(prodEnv, {
	env: 'development',
	admin: {
		url: "http://localhost"
	},
	api: {
		baseUrl: 'http://localhost/api'
	},
	auth: {
		loginUrl: "http://localhost/login",
		logoutUrl: "http://localhost/logout",
		sessionTokenCookieDomain: "localhost",
		sessionTokenCookieName: "eurosteo_token",
		sessionTokenHeaderName: "X-Eurosteo-Authorization",
	},
	app: {
		sessionListRefreshInterval: 5000,
		evaluationViewRefreshInterval: 5000,
	}
})
