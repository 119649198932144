<template>
  <v-list>
    <v-unknown-error-alert v-if="error"/>
    <v-list-item v-if="loading"><v-list-item-content><v-progress-circular indeterminate color="primary"/></v-list-item-content></v-list-item>
    <v-list-item v-else-if="currentSessions.length === 0"><v-list-item-content><v-card-text>{{emptyMessage}} <a v-if="showCreationLink" href="#" @click.prevent="createSessionHasBeenClicked">Créer une consultation.</a></v-card-text></v-list-item-content></v-list-item>

    <div v-else>
      <v-list-item selectable @click="$emit('session-selected', s, i, currentSessions.length)" v-for="(s,i) in currentSessions" :key="i" :three-line="isItemThreeLine">
        <v-list-item-avatar v-if="highlightReadonly" style="position: relative;">
          <v-icon :color="s.isEnded ? 'warning' : 'success'">{{ s.hasMarks ? 'mdi-circle' : 'mdi-circle-outline' }}</v-icon>
          <v-icon x-small style="position: absolute;" v-if="!s.isWritable">mdi-lock</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <span v-if="showStudentName" class="font-weight-medium">{{s.student.fullname}}&nbsp;—&nbsp;</span>
            <span>Réf. <span class="font-weight-bold">{{s.reference}}</span><span v-if="showType">&nbsp;—&nbsp;<span class="font-weight-bold">{{computeTypeLabel(s.type)}}</span></span></span>
          </v-list-item-title>
          <v-list-item-subtitle>
            <span v-if="showBoothLabel" class="font-weight-medium">{{s.booth.label}}&nbsp;—&nbsp;</span>
            <span>{{ s.startedAt | moment('lll') }}</span>
          </v-list-item-subtitle>
          <v-list-item-subtitle  v-if="showSemesterLabel">
            <span>{{s.schoolyearLabel}} - Période {{s.semesterIndex}}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="showSignatureStatus" class="flex flex-row align-center justify-end" style="max-width: 5em">
          <v-btn small class="mr-2" v-if="isEvaluator && !s.signatureStatus.hasBeenSignedBySchool" icon @click.stop="$emit('session-selected-for-signature', s)">
            <v-icon small color="amber lighten-1">mdi-draw-pen</v-icon>
          </v-btn>
          <signature-status-badge :signature-status="s.signatureStatus"/>
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="currentSessions.length > 0 && showCreationLink">
        <v-list-item-content>
          <v-card-text><a href="#" @click.prevent="createSessionHasBeenClicked">Ajouter une nouvelle consultation.</a></v-card-text>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-list>
</template>

<script>
import VUnknownErrorAlert from "@/components/VUnknownErrorAlert";
import {mapGetters, mapState} from "vuex";
import EvaluationBusiness from "@/business/EvaluationBusiness";
import Config from "@/config";
import SignatureStatusBadge from "@/components/SignatureStatusBadge.vue";
export default {
  name: "StudentSessionList",
  components: {SignatureStatusBadge, VUnknownErrorAlert},
  props: {
    studentId: {
      type: Number,
      required: false
    },
    scope: {
      type: String,
      default: 'recent',
      validator: (value) => ['recent', 'opened', 'current-period', 'student-classmates', 'all', 'all-internal', 'all-external', 'all-app', 'older-and-unsigned'].includes(value)
    },
    scopeType: {
      type: String,
      default: 'all',
      validator: (value) => ['internal', 'external', 'ap', 'all'].includes(value)
    },
    showCreationLink: {
      type: Boolean,
      default: false
    },
    highlightReadonly: {
      type: Boolean,
      default: false
    },
    showStudentName: {
      type: Boolean,
      default: false
    },
    showBoothLabel: {
      type: Boolean,
      default: false
    },
    showSemesterLabel: {
      type: Boolean,
      default: false
    },
    autoRefresh: {
      type: Boolean,
      default: true
    },
    showSignatureStatus: {
      type: Boolean,
      default: false
    },
    showType: {
      type: Boolean,
      default: false
    },
    forceCenterId: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    currentSessions: [],
    loading: false,
    error: false,
    currentlyLoadingUrl: null
  }),
  timers: {
    fetchAuto: {repeat: true, immediate: true, time: Config.app.sessionListRefreshInterval}
  },
  watch: {
    studentId(id) {
      this.fetch(id);
    },
    scope() {
      this.$nextTick(()=>this.fetch(this.studentId, true));
    },
    scopeType() {
      this.$nextTick(()=>this.fetch(this.studentId, true));
    },
    forceCenterId() {
      this.$nextTick(()=>this.fetch(this.studentId, true));
    },
    autoRefresh(newVal) {
      if (newVal) {
        this.$timer.start('fetchAuto');
      } else {
        this.$timer.stop('fetchAuto');
      }
    }
  },
  mounted() {
    this.fetch(this.studentId);
    if (this.autoRefresh !== false) {
      this.$timer.start('fetchAuto');
    }
  },
  computed: {
    ...mapGetters("auth", ['isEvaluator']),
    ...mapState("auth", ['user']),

    // Indicates if the v-list-item elements should be three lines tall
    isItemThreeLine() {
      // As of today, only the semester label is shown on the third line
      return this.showSemesterLabel;
    },
    emptyMessage() {
      if (this.scope === 'recent') {
        return "Aucune consultation."
      } else if (this.scope === 'current-period') {
        return "Aucune consultation pour la période actuelle."
      } else  return "Aucune consultation."
    }
  },
  methods: {
    getApiUrl(studentId) {
      let type = this.scopeType; // Maybe be overridden by the scope (for legacy purpose)
      let base;
      if (studentId) {
        if (this.scope === 'recent') {
          base = `/v1/students/${studentId}/evaluations/recent`
        } else if (this.scope === 'current-period') {
          base = `/v1/students/${studentId}/evaluations/for-current-period`
        } else if (this.scope === 'student-classmates') {
          base = `/v1/students/${studentId}/evaluations/opened-by-classmates`
        } else if (this.scope === 'all') {
          base = `/v1/students/${studentId}/evaluations`
        } else if (this.scope === 'all-internal') {
          type = 'internal';
          base = `/v1/students/${studentId}/evaluations`
        } else if (this.scope === 'all-external') {
          type = 'external';
          base = `/v1/students/${studentId}/evaluations`
        } else if (this.scope === 'all-ap') {
          type = 'ap';
          base = `/v1/students/${studentId}/evaluations`
        } else base = ""
      } else if (this.isEvaluator) {
        if (this.scope === 'recent') {
          base = `/v1/evaluators/${this.user.id}/evaluations/recent`
        } else if (this.scope === 'opened') {
          base = `/v1/evaluators/${this.user.id}/evaluations/opened`
        } else if (this.scope === 'older-and-unsigned') {
          base = `/v1/evaluators/${this.user.id}/evaluations/older-and-unsigned`
        } else base = ""
      }

      let url = `${base}?`;
      let hasParams = false;
      if (type) {
        url += `type=${type}`;
        hasParams = true;
      }
      if (this.forceCenterId) {
        hasParams ? url += `&forceCenterId=${this.forceCenterId}` : url += `forceCenterId=${this.forceCenterId}`;
      }
      return url;
    },
    fetchAuto() {
      this.fetch(this.studentId, false);
    },
    refresh() {
      this.fetch(this.studentId, true);
    },
    fetch(id, showLoading) {
      this.loading = showLoading;
      this.error = false;
      const apiUrl = this.getApiUrl(id);
      this.currentlyLoadingUrl = apiUrl;
      this.$http.get(apiUrl)
          .then(r => {
            if (this.currentlyLoadingUrl === apiUrl) {
              this.currentSessions = r.data;
            }
          })
          .catch((e) => {
            if (this.currentlyLoadingUrl === apiUrl) {
              this.error = this.$err(e);
            }
          })
          .finally(() => {
            if (this.currentlyLoadingUrl === apiUrl) {
              this.loading = false;
            }
          })
    },
    createSessionHasBeenClicked() {
      this.$emit('create-session', this.studentId)
    },
    getSessionByIndex(i) {
      return this.currentSessions[i];
    },
    isEvaluationSigned(evaluation) {
      return EvaluationBusiness.hasBeenSignedByEvaluator(evaluation);
    },
    getSessions() {
      return this.currentSessions ? this.currentSessions : [];
    },
    computeTypeLabel(type) {
      return EvaluationBusiness.getLabelType(type);
    }
  }
}
</script>

<style scoped>

</style>
