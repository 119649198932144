<template>
  <v-container>
    <v-btn text @click="$router.push('/student/me')">
      <v-icon>mdi-chevron-left</v-icon>
      Retour
    </v-btn>
    <v-card>
      <v-card-title>Liste des consultations</v-card-title>
      <v-tabs fixed-tabs show-arrows v-model="currentTab">
        <v-tab>Interne</v-tab>
        <v-tab>Externe</v-tab>
        <v-tab>AP</v-tab>
      </v-tabs>
      <student-session-list :student-id="user.id" scope="all" :scope-type="scopeTypeForTab" show-booth-label @session-selected="sessionSelected" show-semester-label show-signature-status/>
    </v-card>
  </v-container>
</template>

<script>
import StudentSessionList from "@/components/StudentSessionList";
import {mapState} from "vuex";

export default {
  name: "StudentEvaluationListView",
  components: {StudentSessionList},
  data: () => ({
    currentTab: 0
  }),
  computed: {
    ...mapState('auth', ['user']),
    scopeTypeForTab() {
      switch (this.currentTab) {
        case 0:
        default:
          return 'internal'
        case 1:
          return 'external'
        case 2:
          return 'ap'
      }
    }
  },
  methods: {
    sessionSelected(s) {
      this.$router.push('/evaluations/' + s.id)
    }
  }
}
</script>

<style scoped>

</style>
