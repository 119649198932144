module.exports = {
	env: 'production',
	admin: {
		url: process.env.VUE_APP_ADMIN_URL
	},
	api: {
		baseUrl: process.env.VUE_APP_API_URL
	},
	auth: {
		mockRedirection: false,
		loginUrl: process.env.VUE_APP_LOGIN_URL,
		logoutUrl: process.env.VUE_APP_LOGOUT_URL,
		sessionTokenHeaderName: process.env.VUE_APP_SESSION_TOKEN_API_HEADER_NAME,
		sessionTokenCookieDomain: process.env.VUE_APP_SESSION_TOKEN_COOKIE_DOMAIN,
		sessionTokenCookieName: process.env.VUE_APP_SESSION_TOKEN_COOKIE_NAME,
	},
	app: {
		sessionListRefreshInterval: process.env.VUE_APP_SESSION_LIST_REFRESH_INTERVAL ? process.env.VUE_APP_SESSION_LIST_REFRESH_INTERVAL : 10000,
		evaluationViewRefreshInterval: process.env.VUE_APP_EVALUATION_VIEW_REFRESH_INTERVAL ? process.env.VUE_APP_EVALUATION_VIEW_REFRESH_INTERVAL : 5000,
	}
}
