<template>
  <v-alert type="error">
    <div class="d-flex flex-row align-center">
      <div class="mr-2 flex-grow-1">
        Une erreur est survenue. Veuillez essayer de rafraichir la page. Si ce problème persiste veuillez contacter le
        support.
      </div>
      <v-btn @click="refresh" fab small><v-icon>mdi-refresh</v-icon></v-btn>
    </div>
  </v-alert>
</template>

<script>
export default {
  name: "VUnknownErrorAlert",
  methods: {
    refresh() {
      location.reload();
    }
  }
}
</script>

<style scoped>

</style>
