<template>
  <div class="d-flex align-center">
    <span class="hidden-xs-only text-uppercase text-caption">{{ user.fullname }}</span>
    <v-btn :color="buttonColor"
           :loading="isValidatingAuthentication" @click="dialogAuth = true"
           icon v-bind:class="{'shaken': shake}">
      <v-icon>mdi-account-circle</v-icon>
    </v-btn>
    <v-dialog v-model="dialogAuth" width="500">
      <v-card>
        <v-card-title class="primary lighten-2 mb-4" primary-title>Eurostéo</v-card-title>
        <v-card-text v-if="isAuthenticated">Vous êtes correctement authentifié·e en tant que <span class="font-weight-bold">{{user.fullname}}</span>.</v-card-text>
        <v-card-text v-else>Vous n'êtes pas authentifié·e.</v-card-text>
        <v-card-text v-if="isAuthenticated && !userType">Cependant, votre compte n'est pas
          autorisé à utiliser cette application.
        </v-card-text>
        <v-card-text v-if="isAdmin"><a :href="$config.admin.url" target="_self">Aller à l'espace Administration.</a></v-card-text>
        <v-card-text v-if="user.isAbleToChangePassword"><a @click.prevent="dialogChangePassword=true">Changer de mot de passe</a></v-card-text>
        <div>
          <v-divider/>
          <v-card-actions>
            <v-btn @click="disconnectUser" v-if="isAuthenticated" color="warning" text>Se déconnecter</v-btn>
            <v-spacer/>
            <v-btn @click="dialogAuth=false" color="secondary" text>Fermer</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogChangePassword" width="500">
      <div class="white flex">
        <v-btn icon @click="dialogChangePassword=false"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <iframe :src="$config.admin.url+'/account'" width="500" height="650"/>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {disconnect} from "@/store/auth-module";

export default {
  name: "ConnectionBadge",
  data: () => ({
    dialogAuth: false,
    dialogChangePassword: false,
    shake: false
  }),
  computed: {
    ...mapState('auth', ['user', 'errorOccurred', 'isAuthenticated', 'needsAuthentication', 'isValidatingAuthentication']),
    ...mapGetters('auth', ['userType', 'isAdmin']),
    buttonColor() {
      return this.errorOccurred ? 'error' : this.needsAuthentication ? 'warning' : this.isAuthenticated ? 'accent' : 'gray';
    }
  },
  methods: {
    disconnectUser() {
      disconnect();
    }
  }
}
</script>

<style scoped>
</style>
