<template>
  <v-card>
    <v-btn icon @click="$emit('close')">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-card-title class="pt-0">Consultations de {{ student.fullname }}</v-card-title>
    <v-container v-if="selectedSessionId!=null">
      <v-row class="e-previous-session-top-nav-bar">
        <v-col cols="12" sm="4" class="d-flex justify-center justify-sm-start">
          <v-btn text @click="selectedSessionId=null">
            <v-icon>mdi-chevron-left</v-icon>
            Retour à la liste
          </v-btn>
        </v-col>
        <v-col cols="4" sm="4" class="d-flex justify-center ">
          <v-btn class="mr-4" x-small icon outlined fab color="primary" :disabled="!selectedHasPreviousInList"
                 @click="previousSession">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn x-small icon fab outlined color="primary" :disabled="!selectedHasNextInList" @click="nextSession">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="8" sm="4" class="d-flex justify-sm-end justify-start">
          <v-progress-circular v-if="loading" indeterminate/>
          <div v-else class="pa-2">
            <span class="caption">Réf. <span
                class="font-weight-bold">{{ selectedEvaluation.reference }}</span> — </span>
            <span class="caption">{{ selectedEvaluation.startedAt | moment('lll') }}</span>
          </div>
        </v-col>

      </v-row>
    </v-container>
    <student-session-list ref="sessionList" scope="current-period" v-show="!selectedSessionId" :student-id="student.id"
                          @session-selected="sessionSelected" show-signature-status/>
    <v-skeleton-loader type="card-heading@2, image" v-if="loading && selectedSessionId == null"/>
    <div class="mt-2" v-if="selectedEvaluation && selectedSessionId != null"
         v-show="!loading || selectedSessionId != null">
      <v-btn color="primary" text block :disabled="selectedEvaluation.globalComments.length === 0"
             @click="showGlobalComments=true">Commentaire<span
          v-if="selectedEvaluation.globalComments.length>1">s</span> ({{ selectedEvaluation.globalComments.length }})
      </v-btn>
      <v-dialog max-width="700" v-model="showGlobalComments">
        <session-comment-card :readonly="true" :evaluation="selectedEvaluation" @close="showGlobalComments=false"/>
      </v-dialog>
      <criteria-set :readonly="true" :criteria-set="selectedEvaluation.criteriaSet"
                    :show-academic-descriptions="showAcademicDescription" :existing-marks="selectedEvaluation.marks"
                    :loading-criteria-ids="[]"/>
    </div>
  </v-card>
</template>

<script>
import CriteriaSet from "@/components/CriteriaSet";
import SessionCommentCard from "@/components/SessionCommentCard";
import StudentSessionList from "@/components/StudentSessionList";

export default {
  name: "PreviousSessionsCard",
  components: {StudentSessionList, SessionCommentCard, CriteriaSet},
  props: ['student', 'showAcademicDescription'],
  data: () => ({
    selectedSessionId: null,
    selectedSessionIndex: null,
    sessionListSize: null,
    selectedEvaluation: null,
    showGlobalComments: false,
    loading: false,
    error: false
  }),
  computed: {
    selectedHasNextInList() {
      return this.selectedSessionIndex + 1 < this.sessionListSize;
    },
    selectedHasPreviousInList() {
      return this.selectedSessionIndex > 0;
    }
  },
  methods: {
    nextSession() {
      this.selectedSessionIndex++;
      this.sessionSelected(this.$refs.sessionList.getSessionByIndex(this.selectedSessionIndex), this.selectedSessionIndex, this.sessionListSize);
    },
    previousSession() {
      this.selectedSessionIndex--;
      this.sessionSelected(this.$refs.sessionList.getSessionByIndex(this.selectedSessionIndex), this.selectedSessionIndex, this.sessionListSize);
    },
    sessionSelected(session, index, sessionCount) {
      this.selectedSessionId = session.id;
      this.selectedSessionIndex = index;
      this.sessionListSize = sessionCount;
      this.loading = true;
      this.error = false;
      this.$http.get('/v1/evaluations/' + session.id,
          {
            headers: {'Content-Type': 'application/json'},
            data: {}
          }) // XXX Temporary fix, server needs the Content-Type header otherwise a HTTP 500 will be thrown
          .then(r => this.selectedEvaluation = r.data)
          .catch(e => this.error = this.$err(e))
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>
@media (max-width: 600px) {
  .e-previous-session-top-nav-bar :nth-child(2) {
    order: 3;
  }
}
</style>
