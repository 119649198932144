
const processedCriteriaMapCache = {};

function processCriteriaMap(criteriaSet) {
	let criteriaForId = {};
	for (let i = 0; i < criteriaSet.categories.length; i++) {
		for (let j = 0; j < criteriaSet.categories[i].criteria.length; j++) {
			let criteria = criteriaSet.categories[i].criteria[j];
			criteriaForId[criteria.id] = {
				...criteria,
				label: `${i + 1}.${j + 1} ${criteria.simpleDescription}`
			};
		}
	}
	return criteriaForId;
}

export default {
	hasBeenSignedByEvaluator(evaluation) {
		return evaluation.metadata && Array.isArray(evaluation.metadata.signatures)
			&& evaluation.metadata.signatures.filter(s => s.user_role === 'evaluator').length > 0;
	},

	getLabelType(type) {
		switch (type) {
			case 'internal':
				return 'Interne';
			case 'external':
				return 'Externe';
			case 'ap':
				return 'AP';
			default:
				return '';
		}
	},

	getFlattenedCriteriaSetWithLabels(criteriaSet) {
		if (!processedCriteriaMapCache[criteriaSet.id]) {
			processedCriteriaMapCache[criteriaSet.id] = processCriteriaMap(criteriaSet);
		}
		return processedCriteriaMapCache[criteriaSet.id];
	},

	getPatientProfessionList() {
		return [
			"Accompagnant éducatif et social",
			"Accompagnateur de tourisme équestre",
			"Accompagnateur de voyages",
			"Accompagnateur en moyenne montagne",
			"Acheteur",
			"Acheteur d'espaces publicitaires",
			"Acheteur international",
			"Acheteur-Vendeur",
			"Acousticien",
			"Actrice",
			"Actuaire",
			"Adjoint de direction",
			"Administrateur de base de données",
			"Administrateur de bases de données",
			"Administrateur de biens",
			"Administrateur de salle de spectacles",
			"Administrateur réseaux",
			"Aérodynamicien",
			"Affréteur",
			"Agenceur d'intérieur bois",
			"Agencier",
			"Agent à domicile",
			"Agent administratif",
			"Agent artistique",
			"Agent d'élevage",
			"Agent d'entretien",
			"Agent d'entretien et de rénovation",
			"Agent d'escale",
			"Agent de bord",
			"Agent de circulation",
			"Agent de circulation et d’accueil",
			"Agent de développement des énergies renouvelables",
			"Agent de développement sportif Parcours",
			"Agent de petite maintenance",
			"Agent de piste",
			"Agent de planning",
			"Agent de police",
			"Agent de préfabrication béton",
			"Agent de presse",
			"Agent de propreté",
			"Agent de recherche privé",
			"Agent de réservation",
			"Agent de sécurité",
			"Agent de sûreté aéroportuaire",
			"Agent de sûreté ferroviaire ou aéroportuaire",
			"Agent de surveillance de la voie publique (ASVP)",
			"Agent de transit",
			"Agent d’intervention clientèle",
			"Agent forestier",
			"Agent funéraire",
			"Agent immobilier",
			"Agent machiniste",
			"Agent sécurité",
			"Agent technique de bureau d’études et des méthodes",
			"Agent territorial spécialisé des écoles maternelles",
			"Agriculture Cavalier de la garde",
			"Agronome",
			"Aide-soignant",
			"Aiguilleur du rail",
			"Ajusteur-monteur",
			"Allergologue",
			"Ambulancier",
			"Analyste de prix",
			"Analyste d’exploitation",
			"Analyste financier",
			"Analyste programmeur",
			"Analyste Web",
			"Anesthésiste-réanimateur",
			"Animateur",
			"Architecte",
			"Architecte en système d'information",
			"Architecte IT",
			"Architecte paysagiste",
			"Architecte Sécurité ",
			"Archiviste",
			"Artiste",
			"Assistant architecte",
			"Assistant blibliothécaire",
			"Assistant commercial",
			"Assistant comptable",
			"Assistant d'éducation",
			"Assistant de direction",
			"Assistant de production",
			"Assistant dentaire",
			"Assistant d’éducation",
			"Assistant marketing",
			"Assistant maternel",
			"Assistant paie et administration",
			"Assistant réalisateur",
			"Assistant RH",
			"Assitant administratif et financier",
			"Assureur",
			"Audioprothésiste",
			"Auditeur financier",
			"Auditeur IT",
			"Auditeur systèmes d’information",
			"Auteur",
			"Auteur et dessinateur de BD",
			"Auxiliaire de puériculture",
			"Auxiliaire de vie sociale",
			"Avocat",
			"Barman",
			"Batelier",
			"Bibliothécaire",
			"Biologiste",
			"Biologiste médical",
			"Biostatisticien",
			"Bottier",
			"Boulanger",
			"Bruiteur",
			"Bûcheron",
			"Cadreur",
			"Canalisateur",
			"Capitaine de bateau de croisière fluviale",
			"Capitaine de voilier",
			"Capitaine grand navire",
			"Capitaine grande pêche",
			"Capitaine navire de service",
			"Capitaine petit navire passagers",
			"Cariste",
			"Carreleur",
			"Carreleur-mosaïste",
			"Carrier extracteur",
			"Carrossier réparateur",
			"Carrossier-constructeur",
			"Cavalier d’entraînement",
			"CEO & CTO",
			"Chargé de communication",
			"Chargé de communication international",
			"Chargé de programmes télévisés",
			"Chargé de promotion marketing",
			"Chargé de recouvrement",
			"Chargé de recrutement",
			"Chargé d’accueil",
			"Chargé d’affaires",
			"Chargé d’affaires en financement de projets",
			"Chargé d’affaires entreprises",
			"Chargé d’analyses marketing",
			"Chargé d’approvisionnement",
			"Chargé d’études marketing",
			"Chargé d’études statistiques",
			"Chargé d’indemnisation",
			"Chargé(e) de Normes et Reportings",
			"Charpentier",
			"Chaudronnier",
			"Chauffagiste",
			"Chauffeur",
			"Chauffeur de taxi",
			"Chauffeur de voitures",
			"Chauffeur-livreur",
			"Chef d'établissement",
			"Chef de chantier",
			"Chef de produit marketing",
			"Chef de projet",
			"Chef de projet Build",
			"Chef de projet marketing",
			"Chef de Projet Monétique",
			"Chef de rayon",
			"Chef de service",
			"Chef des ventes",
			"Chef d’agence responsable de site",
			"Chef d’atelier",
			"Chef d’entreprise",
			"Chef d’équipe",
			"Chef d’escale",
			"Chef d’établissement pénitentiaire",
			"Chefs de chantier",
			"Chercheur",
			"Chercheur en agronomie",
			"Chiropracteur",
			"Chirurgien",
			"Chirurgien orthopédiste",
			"Chirurgien-dentiste",
			"Chômeur.euse",
			"Codeur",
			"Coiffeur",
			"Coloriste",
			"Combattant",
			"Commentateur sportif",
			"Commercial",
			"Commis de cuisine",
			"Commis forestier",
			"Commissaire de police",
			"Commissaire d’exposition",
			"Community Manager",
			"Comptable",
			"Comptable spécialisé",
			"Concepteur de jeux vidéos",
			"Concepteur d’emballage",
			"Concepteur paysagiste",
			"Concepteur rédacteur",
			"Concepteur technique de produit",
			"Concepteur-rédacteur",
			"Concessionnaire automobile",
			"Concierge d’hôtel",
			"Conducteur de camion",
			"Conducteur de car scolaire",
			"Conducteur de grue",
			"Conducteur de train",
			"Conducteur de tramway",
			"Conducteur de véhicule",
			"Conducteur d’engins",
			"Conseiller",
			"Conseiller commercial",
			"Conseiller culturel",
			"Conseiller d'orientation",
			"Conseiller d’insertion sociale et professionnelle",
			"Conseiller en développement rural",
			"Conseiller en insertion professionnelle",
			"Conseiller médico-technique",
			"Conservateur du patrimoine",
			"Consignateur de navire",
			"Consolideur",
			"Constructeur de réseaux électriques et d’éclairage",
			"Constructeur de routes",
			"Constructeur en ouvrages d’art",
			"Consultant",
			"Consultant bien-être",
			"Consultant big data",
			"Consultant chef d’entreprise",
			"Consultant en informatique",
			"Consultant en ingénierie culturelle",
			"Consultant en recrutement",
			"Consultant en système d’information",
			"Consultant front-end",
			"Consultant web analytique",
			"Contrôleur",
			"Contrôleur (trains)",
			"Contrôleur aérien",
			"Contrôleur de gestion",
			"Contrôleur des finances publiques",
			"Contrôleur d’exploitation",
			"Contrôleur financier",
			"Convoyeur de fonds",
			"Coordinateur des opérations ferroviaires",
			"Coordinateur d’essais cliniques",
			"Cordonnier-bottier",
			"Correcteur",
			"Costumier",
			"Courtier",
			"Courtier en assurance",
			"Couvreur",
			"Créateur de bijoux",
			"Créateur de parfums",
			"Créateur-styliste de mode",
			"Critique d’art Parcours",
			"Cuisinier",
			"Décorateur d'intérieur",
			"Dentiste",
			"Designer industriel",
			"Designer Web",
			"Développeur Front End Senior",
			"Développeur front-end",
			"Développeur Full stack",
			"Développeur full stack JS",
			"Développeur full stack PHP",
			"Développeur full-stack",
			"Développeur informatique",
			"Développeur web",
			"Diacre",
			"Diététicien",
			"Directeur artistique",
			"Directeur de Projet SI",
			"Directeur des ventes",
			"Directeur financier",
			"Directeur IT",
			"Directeur RH",
			"Economiste",
			"Educateur de jeunes",
			"Eleveur",
			"Employé de banque",
			"Enseignant.e",
			"Ergothérapeute",
			"Esthéticienne",
			"Etudiant.e",
			"Expert / Analyste Production Bancaire",
			"Femme de ménage",
			"Fleuriste",
			"Gardien",
			"Géomètre",
			"Gestionnaire immobilier",
			"Graphiste",
			"Homme de ménage",
			"Hôtesse de l'air",
			"Huissier",
			"Illustrateur",
			"Infirmier.ère",
			"Ingénieur civil",
			"Ingénieur du BTP",
			"Ingénieur électronicien",
			"Ingénieur en Technologies de l'Information",
			"Inspecteur de l'action sanitaire et sociale",
			"Intégrateur web",
			"Jardinier",
			"Jardinier paysagiste",
			"Journaliste",
			"Juge",
			"Kinésithérapeute",
			"Laboratory and Quality Manager",
			"Linguiste",
			"Machiniste",
			"Magasinier",
			"Maître d'hôtel",
			"Manager IT",
			"Manipulateur radio",
			"Masseur",
			"Mécanicien aéronautique",
			"Médecin",
			"Moniteur d'auto-école",
			"Monteur électricien",
			"Network & Security Support Engineer",
			"Nutritionniste",
			"Officier",
			"Opérateur d'usinage sur commande numérique (UCN)",
			"Opérateur de production",
			"Opticien",
			"Orthophoniste",
			"Ostéopathe",
			"Personal Trainer",
			"Pharmacien",
			"Photographe",
			"Physicien",
			"Physicien médical",
			"Pilote",
			"Politicien",
			"Pompier",
			"Porfesseur des universités",
			"Poseur de sol (solier)",
			"Prêtre",
			"Procureur",
			"Professeur d'éducation physique (EPS)",
			"Professeur de français langue étrangère",
			"Professeur des écoles",
			"Psychologue",
			"Réceptionniste",
			"Réceptionniste d'hôtel",
			"Responsable communication",
			"Responsable grands comptes",
			"Responsable Laboratoire et Qualité",
			"Responsable Performance Industrielle",
			"Responsable Production",
			"Responsable service clientèle",
			"Responsable Social Media",
			"Retraité.e",
			"Sages-femmes",
			"Sans-emploi",
			"Secrétaire",
			"Secrétaire médicale",
			"Serveur",
			"Service Delivery Manager",
			"Skipper",
			"Soldat",
			"Soudeur",
			"Statisticien",
			"Surveillant pénitentiaire",
			"Technicien alarme intrusion",
			"Technicien d'analyses biomédicales",
			"Technicien d'exploitation",
			"Technicien de maintenance informatique",
			"Téléconseiller",
			"Test manager",
			"Travailleur social",
			"Urbaniste",
			"Vendeur",
			"Vétérinaire",
			"videographer",
			"Webmaster"
		];
	}
}
