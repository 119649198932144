<template>
  <div>
    <div
        v-if="user && user.evaluatorDetail && user.evaluatorDetail.availableCenters && user.evaluatorDetail.availableCenters.length > 0"
        class="d-flex flex-column align-center v-btn--block">
      <v-btn-toggle v-model="selectedCenterIndex" dense borderless tile>
        <v-btn :disabled="disabled" v-for="(c,i) in user.evaluatorDetail.availableCenters" :key="i">{{ c.trigram }}</v-btn>
      </v-btn-toggle>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "CenterSelect",
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    selectedCenter: null,
    selectedCenterIndex: 0,
  }),
  computed: {
    ...mapState('auth', ['user']),
  },
  mounted() {
    if (this.user && this.user.evaluatorDetail && this.user.evaluatorDetail.availableCenters && this.user.evaluatorDetail.availableCenters.length > 0) {
      if (this.user.evaluatorDetail.defaultCenter) {
        this.selectedCenter = this.user.evaluatorDetail.defaultCenter;
      } else {
        this.selectedCenter = this.user.evaluatorDetail.defaultCenter[0];
      }
    } else this.$emit('input', null);
  },
  watch: {
    selectedCenter(c) {
      this.$emit('input', c);
      this.selectedCenterIndex = this.user.evaluatorDetail.availableCenters.findIndex(e => e.id === c.id);
    },
    selectedCenterIndex(idx) {
      this.selectedCenter = this.user.evaluatorDetail.availableCenters[idx];
    }
  }
}
</script>

<style scoped>

</style>
