import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fr from 'vuetify/es5/locale/fr';

Vue.use(Vuetify);

export default new Vuetify({
	lang: {
		locales: {fr},
		current: 'fr',
	},
	icons: {
		iconfont: 'mdi',
	},
	theme: {
		themes: {
			light: {
				primary: '#4c9daf',
				secondary: '#6d818a',
				accent: '#8ae4f0',
				error: '#f44336',
				warning: '#ff9800',
				info: '#3f51b5',
				success: '#8bc34a'
			}
		}
	}
});
