<template>
  <v-app>
    <v-app-bar v-if="!$route.query.hasOwnProperty('embedded')" app color="primary" dark>
      <v-toolbar-title class="text-uppercase">Eurostéo</v-toolbar-title>
      <v-spacer/>
      <connection-badge/>
    </v-app-bar>

    <v-main class="colored-background">
      <v-container v-if="needsAuthentication">
        <v-card :loading="true">
          <v-card-title>Authentification nécessaire</v-card-title>
          <v-card-text>Redirection en cours...</v-card-text>
        </v-card>
      </v-container>
      <router-view v-else-if="isAuthenticated"/>
      <v-container v-else>
        <v-progress-linear v-if="isValidatingAuthentication" indeterminate/>
        <v-card v-else-if="tooManyRequestsError">
          <v-card-title>Votre appareil est temporairement suspendu</v-card-title>
          <v-card-text>Suite à un nombre anormal de requêtes provenant de votre appareil, celui-ci est temporairement suspendu. Veuillez attendre une minute avant de rafraichir cette page. Si ce problème persiste, ou si vous ne pensez pas être à l'origine d'un nombre important de requêtes, merci de contacter le support.</v-card-text>
        </v-card>
        <v-card v-else-if="errorOccurred">
          <v-card-title>Une erreur est survenue</v-card-title>
          <v-card-text>Veuillez contacter le support si cette erreur persiste.</v-card-text>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex'
import ConnectionBadge from "@/components/ConnectionBadge";

export default {
  name: 'App',
  components: {ConnectionBadge},
  data: () => ({}),

  mounted() {
    this.checkAuthentication();
  },

  watch: {
    userType(type) {
      if (this.$route.path === "/") {
        if (type === 'student') {
          this.$router.push('/student/me');
        } else if (type === 'evaluator') {
          this.$router.push('/eval');
        } else {
          this.$router.push('/');
        }
      }
    }
  },

  computed: {
    ...mapState('auth', ['errorOccurred', 'isAuthenticated', 'user', 'isValidatingAuthentication', 'needsAuthentication', 'tooManyRequestsError']),
    ...mapGetters('auth', ['userType'])
  },
  methods: {
    ...mapActions('auth', ['checkAuthentication'])
  }
};
</script>

<style scoped>
.colored-background {
  background-color: rgb(242, 243, 245);
}
</style>
