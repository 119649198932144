<template>
  <v-container>
    <v-unknown-error-alert v-if="error"/>
    <div v-else>
      <div class="d-flex align-center">
        <center-select v-model="selectedCenter" :disabled="!!user.evaluatorDetail.restrictedBooth"/>
        <v-spacer/>
        <v-btn text color="primary" @click="createSessionHasBeenClicked">
          Ajouter une consultation
        </v-btn>
      </div>
      <v-card>
        <v-card-title>Rechercher un étudiant</v-card-title>
        <v-card-text>
          <student-select v-model="selectedStudent" :outlined="true" @error="error=true"/>
        </v-card-text>
        <student-session-list scope="recent" :show-creation-link="true" v-if="selectedStudent" :student-id="selectedStudent.id"
                              @session-selected="goToSession" show-type show-signature-status
                              @create-session="createSessionHasBeenClicked(selectedStudent.id)"/>
      </v-card>


      <v-card class="mt-6">

        <v-tabs v-if="!user.evaluatorDetail.restrictedBooth" v-model="sessionTypeTab" fixed-tabs show-arrows>
          <v-tab>Interne</v-tab>
          <v-tab>Externe</v-tab>
          <v-tab>AP</v-tab>
        </v-tabs>
        <v-tabs v-else centered>
          <v-tab>{{user.evaluatorDetail.restrictedBooth.label}}</v-tab>
        </v-tabs>

        <v-card-title>Récemment évaluées</v-card-title>
        <student-session-list ref="evaluatedSessionsList" scope="recent" show-student-name :scope-type="scopeTypeForTab"
                              @session-selected="goToSession" show-booth-label :show-type="scopeTypeForTab === 'all'"
                              show-signature-status @session-selected-for-signature="showSignatureForSession"
                              :force-center-id="selectedCenter == null ? null : selectedCenter.id"/>
        <v-card-title>Récemment ouvertes
          <v-spacer/>
          <v-btn @click="signAllDialogButtonClicked" color="primary" text small>Tout valider
            <v-icon right class="hidden-xs-only">mdi-draw-pen</v-icon>
          </v-btn>
        </v-card-title>
        <student-session-list ref="recentSessionsList" scope="opened" show-student-name :scope-type="scopeTypeForTab"
                              :force-center-id="selectedCenter == null ? null : selectedCenter.id" show-booth-label
                              @session-selected="goToSession"  show-signature-status
                              @session-selected-for-signature="showSignatureForSession"/>

        <v-card-title>
          Passées non validées
          <v-spacer/>
          <unfold-button v-model="showOlderAndUnsignedList"/>
        </v-card-title>
        <student-session-list v-show="showOlderAndUnsignedList" :auto-refresh="showOlderAndUnsignedList"
                              ref="olderAndUnsignedList" show-student-name show-signature-status show-booth-label
                              scope="older-and-unsigned" :scope-type="scopeTypeForTab"
                              :force-center-id="selectedCenter == null ? null : selectedCenter.id"
                              @session-selected="goToSession"
                              @session-selected-for-signature="showSignatureForSession"/>
      </v-card>
    </div>

    <signature-dialog single-session v-if="selectedSessionForSignature !== null" :loading="singleSessionSignatureLoading" :value="true" :session="selectedSessionForSignature" @cancel="selectedSessionForSignature = null" @close="selectedSessionForSignature = null" @validated="singleSessionSigned"/>
    <signature-dialog v-model="showSignAllDialog" :sessions="sessionsToSign" @cancel="showSignAllDialog = false" @close="showSignAllDialog = false" @validated="signAllSessions"/>

  </v-container>
</template>



<script>
import VUnknownErrorAlert from "@/components/VUnknownErrorAlert";
import StudentSelect from "@/components/StudentSelect";
import StudentSessionList from "@/components/StudentSessionList";
import SignatureDialog from "@/components/SignatureDialog.vue";
import EvaluationBusiness from "@/business/EvaluationBusiness";
import {mapState} from "vuex";
import CenterSelect from "@/components/CenterSelect.vue";
import UnfoldButton from "@/components/UnfoldButton.vue";

export default {
  name: "FindSessionView",
  components: {UnfoldButton, CenterSelect, SignatureDialog, StudentSessionList, StudentSelect, VUnknownErrorAlert},
  data: () => ({
    error: false,
    selectedCenter: null,
    selectedStudent: null,
    selectedStudentSessions: null,
    selectedSessionForSignature: null,
    singleSessionSignatureLoading: false,
    singleSessionSignatureError: false,
    multiSessionSignatureLoading: false,
    multiSessionSignatureError: false,
    showSignAllDialog: false,
    showOlderAndUnsignedList: false,
    sessionsToSign: [],
    sessionTypeTab: 0
  }),
  computed: {
    ...mapState("auth", ['user']),
    scopeTypeForTab() {
      switch (this.sessionTypeTab) {
        case 0:
        default:
          return 'internal'
        case 1:
          return 'external'
        case 2:
          return 'ap'
      }
    }
  },
  mounted() {
    if (this.user.evaluatorDetail.restrictedBooth) {
      this.sessionTypeTab = 1; // les restrictedbooth sont forcément externes
    }
  },
  methods: {
    goToSession(session) {
      this.$router.push(`/evaluations/${session.id}`)
    },
    createSessionHasBeenClicked(studentId = null) {
      if (Number.isInteger(studentId)) {
        this.$router.push(`/eval/create-session/student/${studentId}`)
      } else {
        this.$router.push(`/eval/create-session`)
      }
    },
    showSignatureForSession(session) {
      this.selectedSessionForSignature = session;
    },
    singleSessionSigned() {
      this.singleSessionSignatureLoading = true;
      this.singleSessionSignatureError = false;
      this.$http.post(`/v1/evaluations/${this.selectedSessionForSignature.id}/sign`)
          .then(() => {
            this.selectedSessionForSignature = null;
            this.$refs.evaluatedSessionsList.refresh();
            this.$refs.recentSessionsList.refresh();
          })
          .catch(e => this.singleSessionSignatureError = this.$err(e))
          .finally(() => this.singleSessionSignatureLoading = false)
    },
    signAllDialogButtonClicked() {
      this.sessionsToSign = this.$refs.recentSessionsList.getSessions().filter(s => !EvaluationBusiness.hasBeenSignedByEvaluator(s));
      this.showSignAllDialog = true;
    },
    signAllSessions(sessionsToSignIds) {
      if (sessionsToSignIds.length > 0) {
        this.multiSessionSignatureLoading = true;
        this.multiSessionSignatureError = false;
        let sessionIds = sessionsToSignIds.join(',');
        this.$http.post(`/v1/evaluations/${sessionIds}/sign`)
            .then(() => {
              this.showSignAllDialog = false;
              this.$refs.recentSessionsList.refresh();
            })
            .catch(e => this.multiSessionSignatureError = this.$err(e))
            .finally(() => this.multiSessionSignatureLoading = false)
      }
    }
  }
}
</script>

<style scoped>

</style>
