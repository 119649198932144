<template>
  <v-btn icon @click="toggle">
    <v-icon :class="{'rotate-icon': isOpen}">
      mdi-chevron-left
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "UnfoldButton",
  props: {
    value: Boolean,
  },
  data: () => ({
    isOpen: false,
  }),
  watch: {
    value(newValue) {
      this.isOpen = newValue;
    },
  },
  methods: {
    toggle() {
      this.$emit('input', !this.isOpen);
    },
  },
}
</script>

<style scoped>
.rotate-icon {
  transform: rotate(-90deg);
  transition: transform 0.3s ease;
}
</style>
