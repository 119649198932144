<template>
  <v-expansion-panels flat accordion v-model="openedCategory">
    <v-expansion-panel v-for="(category,i1) in criteriaSet.categories" :key="i1" class="v-expansion-panel--narrow">
      <v-expansion-panel-header>
        <div class="d-flex flex-column">
          <span class="criteria-set-category-heading">{{ i1 + 1 }}. {{ category.name }}</span>
          <span class="caption">{{ category.description }}</span>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-list-item v-for="(criteria,i2) in category.criteria" :key="i2">
          <v-list-item-content>
            <div class="d-flex" style="width: 100%">
              <span class="mr-2 font-weight-bold">{{ i1 + 1 }}.{{ i2 + 1 }}</span>
              <span v-if="showAcademicDescriptions">{{ criteria.academicDescription }}</span>
              <span v-else>{{ criteria.simpleDescription }}</span>
              <v-spacer/>
              <criteria-comment-button v-if="!hideComments"
                  :value="marksForCriteria[criteria.id] ? marksForCriteria[criteria.id].comment : 0"
                  :readonly="readonly || (marksForCriteria[criteria.id] && marksForCriteria[criteria.id].evaluator.id !== user.id)"
                  :criteria="criteria" class="flex-grow-0" @input="onCommentInput" :show-academic-description="showAcademicDescriptions"
                  :disabled="(marksForCriteria[criteria.id] ? marksForCriteria[criteria.id].value : 0) === 0"
              />
            </div>
            <criteria-slider
                @input="onCriteriaSliderInput"
                :value="marksForCriteria[criteria.id] ? marksForCriteria[criteria.id].value : 0"
                :readonly="readonly || (marksForCriteria[criteria.id] && marksForCriteria[criteria.id].evaluator.id !== user.id)"
                :criteria="criteria" class="flex-grow-1"
                :loading="loadingCriteriaIds.indexOf(criteria.id)>0"
                :hide-lock-icon="hideSliderLock"
                :evaluator="marksForCriteria[criteria.id] ? marksForCriteria[criteria.id].evaluator : null"
                :average-value="averagesForCriteria[criteria.id] ? averagesForCriteria[criteria.id].value : null"
                :average-count="averagesCountForCriteria[criteria.id] ? averagesCountForCriteria[criteria.id].count : null"
            />
          </v-list-item-content>
        </v-list-item>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>
import CriteriaSlider from "@/components/CriteriaSlider";
import {mapGetters, mapState} from "vuex";
import CriteriaCommentButton from "@/components/CriteriaCommentButton";

export default {
  name: "CriteriaSet",
  components: {CriteriaCommentButton, CriteriaSlider},
  props: ['criteriaSet', 'showAcademicDescriptions', 'readonly', 'existingMarks', 'loadingCriteriaIds', 'hideComments', 'averageMarks', 'averageMarksCount', 'value'],
  data: () => ({
    openedCategory: null
  }),
  mounted() {
    this.openedCategory = this.value;
  },
  watch: {
    value(v) {
      this.openedCategory = v;
    },
    openedCategory(v) {
      this.$emit('input', v);
    }
  },
  computed: {
    ...mapState("auth", ['user']),
    ...mapGetters("auth", ['userType']),
    marksForCriteria() {
      let result = {};
      for (const m of this.existingMarks) {
        result[m.criteriaId] = m;
      }
      return result;
    },
    averagesForCriteria() {
      if (!this.averageMarks) return [];
      let result = {};
      for (const m of this.averageMarks) {
        result[m.criteriaId] = m;
      }
      return result;
    },
    averagesCountForCriteria() {
      if (!this.averageMarksCount) return [];
      let result = {};
      for (const m of this.averageMarksCount) {
        result[m.criteriaId] = m;
      }
      return result;
    },
    hideSliderLock() {
      return this.userType === 'student';
    }
  },
  methods: {
    onCriteriaSliderInput(newVal, criteria) {
      this.$emit('mark-update', criteria, newVal)
    },
    onCommentInput(newVal, criteria) {
      this.$emit('comment-update', criteria, newVal)
    }
  }
}
</script>

<style>
.criteria-set-category-heading {
  font-size: larger;
  font-weight: bolder;
}

.v-expansion-panel--narrow .v-expansion-panel-content__wrap {
  padding: 0;
}

.v-expansion-panel--narrow .v-expansion-panel-header {
  padding: 1em;
}
</style>
