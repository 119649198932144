<template>
    <v-select :disabled="!studentId" v-model="selectedBooth" :loading="loading" outlined return-object :items="availableBooths" item-text="label"
              item-value="id" placeholder="Sélectionnez un lieu" :rules="rules" label="Lieu de la consultation"/>
</template>

<script>
export default {
  name: "BoothSelect",
  props: ['rules', 'studentId', 'value', 'type'],
  data: () => ({
    loading: false,
    booths: [],
    selectedBooth: null,
  }),
  mounted() {
    this.fetchBooth(this.studentId);
  },
  watch: {
    type() {
      this.selectFirstAvailableBooth();
    },
    selectedBooth(c) {
      this.$emit('input', c);
    },
    studentId(v) {
      this.fetchBooth(v);
    }
  },
  computed: {
    availableBooths() {
      return this.booths.length ? this.booths.filter(b => b.isExternal === (this.type === 'external')) : [];
    }
  },
  methods: {
    fetchBooth(studentId) {
      if (!studentId) {
        this.selectedBooth = null;
        this.booths = [];
      } else {
        this.loading = true;
        this.error = false;
        this.$http.get('/v1/booths', {params: {studentId: studentId}})
            .then((r) => {
              this.booths = r.data;

              // Si on a une sélection de fait vi le prop "value", on retrouve le booth correspondant dans la liste via l'id
              if (this.value && this.value.id) {
                for (const booth of this.booths) {
                  if (booth.id === this.value.id) {
                    this.selectedBooth = booth;
                    break;
                  }
                }
              } else if (this.booths.length === 1) { // S'il n'y a qu'un seul lieu, on le pré-sélectionne
                this.selectedBooth = this.booths[0];
              }

            })
            .catch((e) => {
              if (this.$err(e)) {
                this.$emit('error', e);
              }
            })
            .finally(() => this.loading = false)
      }
    },
    selectFirstAvailableBooth() {
      this.selectedBooth = this.availableBooths.length ? this.availableBooths[0] : null;
    }
  }
}
</script>

<style scoped>

</style>
