<template>
  <v-container class="pa-0" v-bind:class="{'pa-sm-3': !$route.query.hasOwnProperty('embedded')}">
    <v-unknown-error-alert v-if="error"/>

    <div v-else>
      <v-snackbar v-model="errorSending" color="error" top>
        Une erreur est survenue lors du dernier envoi, la page doit être rechargée.
      </v-snackbar>
      <v-snackbar v-model="sendingCollision" color="warning" top :timeout="5000">
        <div class="d-flex flex-row align-center">
          <div class="flex-grow-0 mr-3">
            <v-icon>mdi-compare-horizontal</v-icon>
          </div>
          <div class="flex-grow-1">
            Un autre utilisateur a réalisé une modification sur la même compétence au même moment. La page doit être
            rechargée.
          </div>
        </div>
      </v-snackbar>
      <v-snackbar v-model="errorClosing" color="error" top>
        Une erreur est survenue lors de la fermeture de la consultation, veuillez réessayer.
      </v-snackbar>

      <v-btn text @click="$router.back()" v-if="!$route.query.hasOwnProperty('embedded')">
        <v-icon>mdi-chevron-left</v-icon>
        Retour
      </v-btn>

      <v-dialog v-model="showEditionForm" max-width="800">
        <session-edit-card @save="sessionEditionSave" :evaluation="evaluation" @close="showEditionForm=false"/>
      </v-dialog>

      <v-dialog v-model="showPreviousEvaluationsDialog" max-width="800">
        <previous-sessions-card :student="evaluation.student" @close="showPreviousEvaluationsDialog=false" :show-academic-description="showAcademicDescription"/>
      </v-dialog>

      <v-dialog v-if="evaluation.isCancellable" v-model="showCancelSessionDialog">
        <v-card>
          <v-card-title>Annuler la session</v-card-title>
          <v-card-text>Êtes-vous sûr de vouloir annuler cette session {{ evaluation.reference }} ?</v-card-text>
          <v-card-actions>
            <v-btn text color="primary" @click="showCancelSessionDialog = false">Non</v-btn>
            <v-spacer/>
            <v-btn text color="warning" @click="cancelSession">Oui je suis sûr</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card :color="loading ? 'white' : 'primary lighten-1'">
        <v-skeleton-loader type="card-heading@2, image" v-if="loading"/>
        <div v-else>

          <div class="e-student-name-fixed" v-bind:class="{'show': showFixedTopBar}">
            {{ evaluation.student.fullname }}
          </div>

          <v-card-title>

            <div class="d-flex flex-row" style="width: 100%">
              <span ref="sessionCardStudentName">{{ evaluation.student.fullname }}
                  <v-menu v-if="areDetailsUpdatable">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon small v-bind="attrs" v-on="on"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                    </template>
                    <v-list>
                      <v-list-item icon @click="showEditionForm=true">
                        <v-list-item-title>Modifier le détail de la consultation</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="isCancellable" icon @click="showCancelSessionDialog=true">
                        <v-list-item-title>Annuler la consultation</v-list-item-title>
                      </v-list-item>
                      <v-list-item icon @click="showPreviousEvaluationsDialog=true">
                        <v-list-item-title>Voir les consultations précédentes</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <v-btn small icon v-if="$route.query.hasOwnProperty('embedded') && isAdmin"
                         @click="showEditionForm=true"><v-icon small>edit</v-icon></v-btn>

                </span>
              <v-spacer/>
              <span class="e-save-indicator-container" v-if="loadingCriteriaIds.length > 0">
                    <v-icon small color="amber">mdi-floppy</v-icon>
                </span>
              <signature-status-badge :signature-status="evaluation.signatureStatus"/>
            </div>

            <div class="d-flex flex-row" style="width: 100%">
              <div class="flex-grow-1 d-flex flex-column">
                <span class="caption">Référence : <span
                    class="font-weight-bold">{{ evaluation.reference }}</span>&nbsp;—&nbsp;<span class="font-weight-bold">{{labelForEvaluationType}}</span></span>
                <span class="caption" v-if="evaluation.type !== 'ap'">{{ evaluation.booth.label }}</span>
                <span class="caption">{{ evaluation.startedAt | moment('LLLL') }}</span>
              </div>
              <div class="flex-grow-0 d-flex align-end flex-column justify-center">
                <div class="d-flex flex-row align-center flex-grow-1">
                  <span class="mr-5 e-comment-button-container">
                    <v-btn icon @click="showGlobalCommentForm=true">
                      <span v-bind:class="{'accent--text text--lighten-1': evaluation.globalComments.length > 0}">
                        {{ evaluation.globalComments.length }}
                      </span>
                      <v-icon>mdi-comment</v-icon>
                    </v-btn>
                    <v-dialog v-model="showGlobalCommentForm" max-width="800">
                      <session-comment-card @save="sessionCommentSave" :evaluation="evaluation" :readonly="isReadonly" @close="showGlobalCommentForm=false"/>
                    </v-dialog>
                  </span>
                  <div class="flex-grow-1">
                    <session-description-type-select v-model="showAcademicDescription"/>
                  </div>
                </div>
              </div>
            </div>
          </v-card-title>
          <div class="d-flex justify-center mb-4">
            <v-chip v-if="evaluation.isEnded" color="warning">Cette consultation a été clôturée</v-chip>
          </div>
          <criteria-set :readonly="isReadonly" :criteria-set="evaluation.criteriaSet"
                        :show-academic-descriptions="showAcademicDescription" :existing-marks="evaluation.marks"
                        @mark-update="onMarkUpdate" @comment-update="onCommentUpdate"
                        :loading-criteria-ids="loadingCriteriaIds"/>
          <v-card-actions class="justify-end">
            <signature-dialog single-session v-model="showSignatureDialog" @validated="signatureValidated"
                              :session="evaluation" :loading="closing" :student="isSelfSession"/>
            <v-btn v-if="isClosableByStudent" color="warning lighten-3" text @click="showSignatureDialog=true">Valider
              la consultation
            </v-btn>
            <v-btn v-if="isSignableByEvaluator" color="warning lighten-3" text @click="showSignatureDialog=true">Valider
              la consultation
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import CriteriaSet from "@/components/CriteriaSet";
import {mapGetters, mapState} from "vuex";
import VUnknownErrorAlert from "@/components/VUnknownErrorAlert";
import SessionEditCard from "@/components/SessionEditCard";
import SessionCommentCard from "@/components/SessionCommentCard";
import PreviousSessionsCard from "@/components/PreviousSessionsCard";
import SessionDescriptionTypeSelect from "@/views/common/SessionDescriptionTypeSelect";
import SignatureDialog from "@/components/SignatureDialog.vue";
import EvaluationBusiness from "@/business/EvaluationBusiness";
import Config from "@/config";
import SignatureStatusBadge from "@/components/SignatureStatusBadge.vue";

const emptyEvaluation = {
  id: null,
  startedAt: null,
  isWritable: null,
  reference: null,
  globalComments: [],
  marks: [],
  student: {
    id: null,
    name: null
  },
  criteriaSet: {
    id: null,
    categories: []
  }
}

const emptyMark = {
  id: null,
  criteriaId: null,
  evaluator: {
    id: null,
  },
  comment: null,
  value: null
}

export default {
  name: "SessionView",
  components: {
    SignatureStatusBadge,
    SignatureDialog,
    SessionDescriptionTypeSelect,
    PreviousSessionsCard,
    SessionCommentCard,
    SessionEditCard,
    VUnknownErrorAlert,
    CriteriaSet
  },
  props: ['evaluationId'],
  data: () => ({
    loading: false,
    error: true,
    errorSending: false,
    errorClosing: false,
    sendingCollision: false,
    closing: false,
    showAcademicDescription: false,
    showEditionForm: false,
    showGlobalCommentForm: false,
    showPreviousEvaluationsDialog: false,
    evaluation: Object.assign({}, emptyEvaluation),
    loadingCriteriaIds: [],
    showFixedTopBar: false,
    showSignatureDialog: false,
    showCancelSessionDialog: false
  }),
  mounted() {
    if (this.evaluationId) {
      this.fetchEvaluation(this.evaluationId);
    }
    if (this.userType !== 'student') {
      window.addEventListener('scroll', this.onScroll)
    }
  },
  beforeDestroy() {
    if (this.userType !== 'student') {
      window.removeEventListener('scroll', this.onScroll)
    }
  },
  watch: {
    evaluationId(v) {
      this.fetchEvaluation(v);
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['userType', 'isAdmin']),
    isSelfSession() {
      return this.user.id === this.evaluation.student.id;
    },
    isReadonly() {
      let readonlyInQueryParam = Object.keys(this.$route.query).indexOf('readonly') >= 0;
      let notWritable = !this.evaluation.isWritable;
      let b = (this.userType !== 'evaluator' && this.evaluation.type !== 'ap' && !this.isSelfSession);
      return notWritable || readonlyInQueryParam || b;
    },
    areDetailsUpdatable() {
      return !this.isReadonly && (this.userType !== 'student' || this.isSelfSession) && !Object.keys(this.$route.query).indexOf('embedded') >= 0; // Seuls les évaluateurs peuvent modifier le détail d'une session
    },
    isClosableByStudent() {
      return this.isSelfSession && !this.evaluation.signatureStatus.hasStudentSigned;
    },
    isSignableByEvaluator() {
      return ((this.userType === 'evaluator' && this.user.isAbleToSignEvaluations) || this.evaluation.type === 'ap') && !this.hasBeenSignedByEvaluator && !this.isSelfSession;
    },
    hasBeenSignedByEvaluator() {
      return EvaluationBusiness.hasBeenSignedByEvaluator(this.evaluation);
    },
    labelForEvaluationType() {
      return EvaluationBusiness.getLabelType(this.evaluation.type);
    },
    isCancellable() {
      return this.evaluation.isCancellable && (this.userType === 'evaluator' || this.isSelfSession);
    }
  },
  timers: {
    refresh: {
      autostart: true,
      repeat: true,
      time: Config.app.evaluationViewRefreshInterval
    }
  },
  methods: {
    refresh() {
      if ((this.userType === 'student' || (this.userType === 'evaluator' && this.loadingCriteriaIds.length === 0)) && !this.showGlobalCommentForm && !this.showEditionForm) {
        this.fetchEvaluation(this.evaluationId, false);
      }
    },
    fetchEvaluation(id, showLoading = true) {
      this.error = false;
      this.loading = showLoading;
      this.$http.get('/v1/evaluations/' + id,
          {
            headers: {'Content-Type': 'application/json'},
            data: {}
          }) // XXX Temporary fix, server needs the Content-Type header otherwise a HTTP 500 will be thrown
          .then(r => {
            // If we are currently sending a new value for a criteria, we ignore the result of the request, and request again
            // Without this trick, the UI would show the brand new value for a split second, make it disappear because of the result of this very request, and then show it again at the next refresh loop
            if (this.loadingCriteriaIds.length === 0) {
              this.evaluation = r.data;
            } else this.fetchEvaluation(id, showLoading);
          })
          .catch(e => this.error = this.$err(e))
          .finally(() => this.loading = false)
    },
    onMarkUpdate(criteria, newVal) {
      this.patchEvaluation(criteria.id, {value: newVal});
    },
    onCommentUpdate(criteria, newVal) {
      this.patchEvaluation(criteria.id, {comment: newVal});
    },
    patchEvaluation(criteriaId, payload) {
      this.loadingCriteriaIds.push(criteriaId);
      this.assignNewMarkToEvaluation(criteriaId, payload);

      // If new value is 0, we delete the mark
      let request = payload.value === 0 ?
          this.$http.delete(`v1/evaluations/${this.evaluationId}/marks/${criteriaId}`) :
          this.$http.put(`v1/evaluations/${this.evaluationId}/marks/${criteriaId}`, payload);

      request
          .then(() => {
          }) // Nothing to do, local data already has been manually updated
          .catch(e => {
            if (e && e.response && e.response.status === 400) { // 400 means this criteria shouldn't have been modified, probably meaning there is a "collision" between two simultaneous update
              this.sendingCollision = true;
              this.fetchEvaluation(this.evaluationId);
            } else if (this.$err(e)) {
              this.errorSending = true;
              this.fetchEvaluation(this.evaluationId)
            }
          })
          .finally(() => {
            this.loadingCriteriaIds.splice(this.loadingCriteriaIds.indexOf(criteriaId), 1);
          })
    },
    assignNewMarkToEvaluation(criteriaId, payload) {
      let assigned = false;

      // We only add the mark if it's value is positive, otherwise we delete it
      if (payload.value === 0) {
        // Value is 0, we delete the mark
        for (let i = 0; i < this.evaluation.marks.length; i++) {
          if (this.evaluation.marks[i].criteriaId === criteriaId) {
            this.evaluation.marks.splice(i, 1);
            break;
          }
        }
      } else {
        // Does the mark already existed and needs to be updated ?
        for (const mark of this.evaluation.marks) {
          if (mark.criteriaId === criteriaId) {
            Object.assign(mark, payload);
            if (payload.value === 0) {
              mark.evaluator = null;
            }
            assigned = true;
            break;
          }
        }

        // If it is a new mark, we add it
        if (!assigned) {
          this.evaluation.marks.push(Object.assign(Object.assign({}, emptyMark), {
            criteriaId,
            evaluator: {...this.user},
            ...payload
          }))
        }
      }
    },
    sessionEditionSave() {
      this.showEditionForm = false;
      this.fetchEvaluation(this.evaluationId, false);
    },
    sessionCommentSave() {
      this.showGlobalCommentForm = false;
      this.fetchEvaluation(this.evaluationId, false);
    },
    signatureValidated() {
      if (this.userType === 'evaluator') {
        this.validateSessionForEvaluator();
      } else if (this.userType === 'student' && this.isSelfSession) {
        this.closeSessionWithSignatureForStudent();
      }
    },
    closeSessionWithSignatureForStudent() {
      this.closing = true;
      this.errorClosing = false;
      this.$http.post(`/v1/evaluations/${this.evaluationId}/close`)
          .then(() => {
            this.$router.push('/student/me');
            this.showSignatureDialog = false;
          })
          .catch(e => this.errorClosing = this.$err(e))
          .finally(() => this.closing = false)
    },
    validateSessionForEvaluator() {
      this.closing = true;
      this.errorClosing = false;
      this.$http.post(`/v1/evaluations/${this.evaluationId}/sign`)
          .then(() => {
            // For the UI to be immediately impacted, we add the signature in the local metadata too (it is obviously added on the server side too)
            this.evaluation.metadata = this.evaluation.metadata ? this.evaluation.metadata : {};
            this.evaluation.metadata.signatures = Array.isArray(this.evaluation.metadata.signatures) ? this.evaluation.metadata.signatures : [];
            this.evaluation.metadata.signatures.push({user_id: this.user.id, user_role: 'evaluator'});
            this.evaluation.signatureStatus.hasEvaluatorSigned = true;
            this.showSignatureDialog = false;
            this.refresh();
          })
          .catch(e => this.errorClosing = this.$err(e))
          .finally(() => this.closing = false)
    },
    onScroll() {
      // Handling the fixed top bar showing -> it should only be visible when we scrolled past the student name in the v-card-title
      const threshold = 52;
      let a = this.$refs.sessionCardStudentName.getBoundingClientRect().y;
      if (this.showFixedTopBar) { // already fixed to the top, we check for scrolling down
        if (a > threshold) { // if we've reached the braking point, we release the name
          this.showFixedTopBar = false;
        }
      } else { // still in the frame, we check for scrolling up
        if (a < threshold) { // if we've reached the braking point, we fix the name
          this.showFixedTopBar = true;
        }
      }
    },
    cancelSession() {
      this.$http.post(`/v1/evaluations/${this.evaluation.id}/cancel`, {id: this.evaluation.id})
          .then(() => this.$router.back())
          .catch(e => this.error = this.$err(e))
          .finally(() => this.showCancelSessionDialog = false)
    }
  }

}
</script>

<style scoped>

@keyframes blink {
  50% {
    opacity: .3;
  }
}

@media (max-width: 600px) {
  .e-student-name-fixed {
    background-color: #69B8CA;
    z-index: 4;
    position: fixed;
    width: 100%;
    text-align: center;
    top: 32px;
    transition: top .1s ease-in-out;
    display: block !important;
  }

  .e-student-name-fixed.show {
    top: 56px;
  }
}

.e-student-name-fixed {
  display: none;
}

.e-save-indicator-container > i {
  animation: blink 1s ease-in-out 0s infinite;
}

.e-comment-button-container {
  position: relative;
}

.e-comment-button-container span {
  position: absolute;
  top: 2px;
  z-index: 1;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  55% {
    opacity: 1;
    transform: scale3d(1.4, 1.4, 1.4);
  }

  to {
    opacity: 0;
    rotate: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.e-bounce-out {
  animation-duration: 1s;
  animation-name: bounceOut;
  animation-fill-mode: forwards;
}

</style>
