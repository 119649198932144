<template>
  <v-autocomplete
      :outlined="outlined"
      v-model="selectedStudent"
      :items="studentAutocomplete.items"
      :loading="studentAutocomplete.loading"
      :search-input.sync="studentAutocomplete.search"
      :hint="computedHint"
      persistent-hint
      hide-no-data
      item-text="fullname"
      item-value="id"
      label="Rechercher un étudiant"
      placeholder="Nom ou prénom de l'étudiant"
      prepend-inner-icon="mdi-account-search"
      clearable
      return-object
      :rules="rules"
  />
</template>

<script>
export default {
  name: "StudentSelect",
  props: ['value', 'outlined', 'rules'],
  data: () => ({
    selectedStudent: null,
    studentAutocomplete: {
      loading: false,
      items: [],
      search: ""
    }
  }),
  timers: {
    fetchStudentAutocomplete: {time: 500}
  },
  watch: {
    "studentAutocomplete.search"() {
      this.$timer.stop("fetchStudentAutocomplete");
      this.$timer.start("fetchStudentAutocomplete");
    },
    selectedStudent(v) {
      this.$emit('input', v);
    }
  },
  computed: {
    computedHint() {
      return this.selectedStudent ? this.selectedStudent.center.label + ' — ' + this.selectedStudent.schoolyear.label : null;
    }
  },
  methods: {
    fetchStudentAutocomplete() {
      if (!this.selectedStudent && this.studentAutocomplete.search && this.studentAutocomplete.search.length >= 2) {
        this.studentAutocomplete.loading = true;
        this.$http.get('/v1/students', {params: {search: this.studentAutocomplete.search, assigned: true}})
            .then(d => this.studentAutocomplete.items = d.data)
            .catch(e => {
              if (this.$err(e)) {
                this.$emit('error', e);
              }
            })
            .finally(() => this.studentAutocomplete.loading = false)
      }
    }
  }
}
</script>

<style scoped>

</style>
