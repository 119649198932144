<template>
  <v-dialog v-model="showDialog">
    <v-tooltip left></v-tooltip>
    <v-card :loading="loading">


      <!-- mono-session -->
      <template v-if="singleSession">
        <v-card-title class="text-break mb-2">Validation de la consultation</v-card-title>
        <v-card-subtitle class="pb-0" v-if="!student">{{session.student.fullname}}</v-card-subtitle>
        <v-card-subtitle v-if="singleSession">Réf. {{session.reference}}&nbsp;—&nbsp;{{computeTypeLabel(session.type)}}</v-card-subtitle>
        <v-card-text v-if="student">
          Je valide et clôture la consultation.
        </v-card-text>
        <v-card-text v-else>
          Résumé de la consultation et notes saisies :
          <signature-dialog-session-summary :session="session"/>
          <div class="caption mt-2">
            Il sera nécessaire de valider à nouveau si une note est ajoutée ou modifiée.
          </div>
        </v-card-text>
      </template>


      <!-- multi-session -->
      <template v-else>
        <v-card-title>Validation de consultations</v-card-title>
        <v-card-text>
          Je signe les consultations suivantes :
          <ul class="ma-4 font-italic" v-if="!sessions.length">Aucune consultation à valider.</ul>
        </v-card-text>
        <v-expansion-panels v-if="sessions.length" accordion>
          <v-expansion-panel v-for="session in sessionsSorted" :key="session.id">
            <v-expansion-panel-header>
              <v-checkbox class="mt-0" @click.stop hide-details v-model="checkedSessions" :value="session.id" label>
                <template v-slot:label>
                  <span class="font-weight-bold">{{session.student.fullname}}</span>&nbsp;— Réf. {{session.reference}} — {{computeTypeLabel(session.type)}}
                </template>
              </v-checkbox>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <signature-dialog-session-summary class="pt-2" :session="session"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

        <v-card-text v-if="thereIsSomethingToSign">
          <div class="px-2 pb-1 elevation-1 rounded-pill primary">
          <v-switch class="font-weight-bold text--white" color="primary lighten-4" label hide-details v-model="signed" :false-value="false" :true-value="true">
            <template v-slot:label>
              <span v-if="singleSession" style="color: white;">En validant, j'atteste que cette consultation a bien eu lieu.</span>
              <span v-else style="color: white;">En validant, j'atteste que ces consultations ont bien eu lieu.</span>
            </template>
          </v-switch>
          </div>
        </v-card-text>

      <v-card-actions v-if="thereIsSomethingToSign">
        <v-btn color="secondary" text @click="cancel">Annuler</v-btn>
        <v-spacer/>
        <v-btn v-if="singleSession" color="primary" text :disabled="!signed" :loading="loading" @click="save">Valider</v-btn>
        <v-btn v-else color="primary" text :disabled="!signed || checkedSessions.length === 0" :loading="loading" @click="save">Valider {{checkedSessions.length}} session<template v-if="checkedSessions.length > 1">s</template></v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer/>
        <v-btn color="primary" text @click="cancel">Retour</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>
<script>

import EvaluationBusiness from "@/business/EvaluationBusiness";
import {mapState} from "vuex";
import SignatureDialogSessionSummary from "@/components/SignatureDialogSessionSummary.vue";

export default {
  name: "SignatureDialog",
  components: {SignatureDialogSessionSummary},
  props: {
    value: {
      type: Boolean,
      required: true
    },
    sessions: {
      type: Array
    },
    session: {
      type: Object
    },
    singleSession: Boolean,
    evaluator: Boolean,
    student: Boolean,
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showDialog: false,
    signed: false,
    error: false,
    checkedSessions: []
  }),
  watch: {
    value(newVal) {
      this.showDialog = newVal;
    },
    showDialog(newVal) {
      if (!newVal) {
        this.$emit('input', newVal); // We only emit events in case of a closing (since the opening is only possible when asked by the parent)
        this.$emit('close');
      }
      this.signed = false;
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    thereIsSomethingToSign() {
      return this.singleSession || this.sessions.length > 0;
    },
    sessionsSorted() {
      return this.sessions ? [...this.sessions].sort((a, b) => a.student.fullname.localeCompare(b.student.fullname)) : [];
    }
  },
  mounted() {
    this.showDialog = this.value;
  },
  methods: {
    clear() {
      this.signed = false;
    },
    save() {
      if (this.signed) {
        this.$emit('validated', (this.singleSession ? this.session.id : this.checkedSessions));
      }
    },
    cancel() {
      this.$emit('input', false);
      this.$emit('cancel');
    },
    forceRerender() {
      window.dispatchEvent(new Event('resize'));
    },
    computeTypeLabel(type) {
      return EvaluationBusiness.getLabelType(type);
    }
  }
}
</script>


<style scoped>

</style>
