<template>
  <v-container>
    <div v-if="error">
      <v-snackbar color="warning" top v-if="errorMessage" :value="true" :timeout="8000">{{ errorMessage }}</v-snackbar>
      <v-unknown-error-alert v-else/>
    </div>
    <div>
      <div class="d-flex">
        <v-btn text @click="$router.back()">Retour</v-btn>
      </div>
      <v-card>
        <v-card-title>Ajouter une consultation</v-card-title>
        <v-card-text>
          <create-session-form v-model="form" ref="form" :student-id="preselectedStudentId" @error="error=true"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn :loading="sending" text color="primary" @click="submit">Ajouter la consultation</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import {redirectToLogin} from "@/store/auth-module";
import CreateSessionForm from "@/components/CreateSessionForm";
import VUnknownErrorAlert from "@/components/VUnknownErrorAlert";

const defaultForm = {
  type: null,
  studentId: null,
  boothId: null,
  reference: "",
  metadata: {
    patientAge: null,
    patientGender: null,
    patientProfession: null,
    patientReasonForConsultation: null
  }
}

export default {
  name: "CreateSessionView",
  components: {VUnknownErrorAlert, CreateSessionForm},
  data: () => ({
    error: false,
    errorMessage: null,
    sending: false,
    preselectedStudentId: null,
    displayStudentSelection: false,
    form: {...defaultForm}
  }),
  props: ['studentId'],
  mounted() {
    /** This view can be loaded in 3 different ways :
     * - as a student (the selected student therefore being the current user)
     * - as an evaluator with a student already selected and passed as route param
     * - as an evaluator without a student already selected
     */
    // First case : student -> we use the current user id as selectedStudentId
    if (this.isStudent) {
      let currentUser = this.$store.state.auth.user;
      if (!currentUser || !currentUser.id) {
        // In the (impossible ?) case there is no current user, or if the user object is incomplete, we redirect for login
        redirectToLogin();
      } else {
        this.preselectedStudentId = currentUser.id;
      }
    }
    // Second case : evaluator with selected student passed as route param -> we use the prop if valid
    if (this.studentId && !isNaN(Number.parseInt(this.studentId)) && this.studentId > 0) {
      this.preselectedStudentId = Number.parseInt(this.studentId);
    }

    // Third case : evaluator without student -> we display the student select component
    // Nothing to do :)
  },
  computed: {
    isStudent() {
      return Array.isArray(this.$route.meta.roles) && this.$route.meta.roles.indexOf('student') >= 0;
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.sending = true;
        this.$http.post('/v1/evaluations', this.form)
            .then((r) => {
              this.$router.push(`/evaluations/${r.data.id}`)
            })
            .catch(e => {
              if (e.response && e.response.data && e.response.data.humanReadableError) {
                this.errorMessage = e.response.data.humanReadableError;
              } else this.errorMessage = null;
              this.error = this.$err(e);
            })
            .finally(() => this.sending = false)
        this.error = false;
      }
    }
  }
}
</script>

<style scoped>

</style>
