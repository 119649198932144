<template>
  <div class="d-flex flex-row">
    <div v-if="!readonly" class="e-custom-slider-lock-container">
      <v-btn icon v-if="isUnlocked" @click="isUnlocked=false" color="accent" ><v-icon>mdi-lock-open</v-icon></v-btn>
      <v-btn icon outlined v-else @click="isUnlocked=true"><v-icon>mdi-lock</v-icon></v-btn>
    </div>
    <div class="e-custom-slider-container flex-grow-1">

      <!-- Following div is placed behind the Vuetify slider, and displays a gradient dynamically generated based on the criteria threshold -->
      <!-- The gradient is calculated using the gradientCss computed value -->
      <!-- Two divs wrap the main gradient, placed at the left and right of it. Their color is fixed, and matches the gradient colors at both its ends -->
      <div class="e-custom-slider-background elevation-1">
        <div class="e-custom-slider-background-stop first d-flex justify-end"
             v-bind:class="{'e-transparent' : curVal===0}">
          <v-icon v-if="readonly && !hideLockIcon" x-small color="#414141">mdi-lock</v-icon>
        </div>
        <div class="e-custom-slider-background-gradient" :style="gradientCss">

          <v-tooltip bottom v-if="averageValue" >
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs" style="" class="e-custom-slider-average-marker" :style="averageMarkerCss"/>
            </template>
            <span>Moyenne de la promotion</span>
        </v-tooltip>

        </div>
        <div class="e-custom-slider-background-stop last" v-bind:class="{'e-transparent' : curVal===0}"/>
      </div>

      <v-slider :readonly="readonly || !isUnlocked" class="e-custom-v-slider-component" v-model="curVal"
                min="0" max="100" :color="thumbColor" :track-color="trackColor" :track-fill-color="trackColor"
                @mouseup="onSliderValueChanged" v-bind:class="{'locked' : readonly}"
                :messages="sliderMessage"/>
      <!-- @mouseup is triggered when the user releases the slider. It works on desktops and on touch screens. -->

      <v-progress-linear v-if="loading" indeterminate height="2" color="grey" class="e-custom-slider-progress"/>

    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "CriteriaSlider",
  props: ['value', 'criteria', 'readonly', 'hideLockIcon', 'loading', 'evaluator', 'averageValue', 'averageCount'],
  data: () => ({
    curVal: 0,
    isUnlocked: false
  }),
  mounted() {
    if (this.value) {
      this.curVal = this.value;
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    sliderMessage() {
      let labelEvaluator = this.labelEvaluator(this.evaluator, this.user);
      return labelEvaluator ? labelEvaluator : this.labelAverageCount(this.averageCount);
    },
    trackColor() {
      return this.readonly ? '#5e5e5e' : 'white';
    },
    threshold() {
      return this.criteria.threshold;
    },
    gradientCss() {
      const t = this.threshold;
      const a = this.curVal === 0 ? '.5' : '1'
      // return `background: linear-gradient(90deg, rgba(255,0,0,${a}) 0%, rgba(255,255,0,${a}) ${t / 2}%, rgba(0,255,0,${a}) ${t}%, rgba(0,255,255,${a}) ${(t + 100) / 2}%, rgba(0,0,255,${a}) 100%)` // On conserve le gradient "de base" (couleurs "vives") au cas où la version retravaillée ne conviendrait pas
      return `background: linear-gradient(90deg, rgba(221,51,51,${a}) 0%, rgba(245,230,60,${a}) ${t / 1.27}%, rgba(43,231,43,${a}) ${t * 1.25}%, rgba(22,215,218,${a}) ${(t + 100) / 1.85}%, rgba(48,48,233,${a}) 100%)`
    },
    averageMarkerCss() {
      if (!this.averageValue) return '';
      let color = this.averageValue >= this.threshold ? '#04cc04' : "#cc2727";
      return `left: ${this.averageValue}%; background-color: ${color}`;
    },
    thumbColor() {
      return this.curVal === 0 ? 'white' : this.curVal >= this.threshold ? '#039403' : "#7e1818";
    }
  },
  watch: {
    value(v) {
      this.curVal = v;
    },
  },
  methods: {
    onSliderValueChanged() {
      if (this.curVal !== this.value) {
        this.$emit('input', this.curVal, this.criteria);
      }
      this.isUnlocked = false;
    },
    labelEvaluator(evaluator, currentUser) {
      return evaluator ? ('Évalué par ' + (evaluator.id === currentUser.id ? 'vous' : evaluator.fullname)) : null;
    },
    labelAverageCount() {
      return this.averageCount ? `Moyenne étudiant basée sur ${this.averageCount} note${this.averageCount > 1 ? 's' : ''}` : null;
    }
  }
}
</script>

<style>

.e-custom-slider-container .v-slider__thumb {
  height: 16px;
  width: 16px;
}

.e-custom-slider-container .v-slider__thumb:before {
  left: -11px;
  top: -11px;
  opacity: .4;
}

.e-custom-slider-container {
  margin-top: .5em;
  /** Relative positioning allows to the "e-custom-slider-background" div to be placed behind the Vuetify slider,
      since "absolute" positioning is relative to the nearest positioned parent **/
  position: relative;
}

.e-custom-slider-background {
  position: absolute; /** This absolute positioning will be relative to the "e-custom-slider-container" div **/
  top: 0;
  width: 100%;
  height: 2em;
  display: flex;
  border-radius: 1em;
}

.e-custom-slider-background-gradient {
  flex: auto;
  border-top: 1px solid #605f5f;
  border-bottom: 1px solid #605f5f;
  padding: 0 3px 0 0; /* For the average marker to be aligned with the cursor */
}

.e-custom-slider-background-stop {
  flex: 0 0 auto;
  width: 1em;
}

.e-custom-slider-background-stop.first {
  background: rgb(221, 51, 51); /** Matches the extreme left color of the slider gradient **/
  border-radius: 1em 0 0 1em;
  border-left: 1px solid #605f5f;
  border-top: 1px solid #605f5f;
  border-bottom: 1px solid #605f5f;
}

.e-custom-slider-background-stop.last {
  background: rgb(48, 48, 233); /** Matches the extreme right color of the slider gradient **/
  border-radius: 0 1em 1em 0;
  border-right: 1px solid #605f5f;
  border-top: 1px solid #605f5f;
  border-bottom: 1px solid #605f5f;
}

.e-custom-slider-background-stop.first.e-transparent {
  background: rgba(221, 51, 51, .5); /** Matches the extreme left color of the slider gradient when curVal = 0 **/
}

.e-custom-slider-background-stop.last.e-transparent {
  background: rgba(48, 48, 233, .5); /** Matches the extreme right color of the slider gradient when curVal = 0 **/
}

.e-custom-v-slider-component {
  padding: 0 .6em; /** So the Vuetify slider doesn't steps on the two "e-custom-slider-background-stop" divs **/
}

.e-custom-v-slider-component .v-slider__thumb {
  border-color: white !important;
  border-style: solid;
  border-width: 1px;
  z-index: 100;
}

.e-custom-v-slider-component.locked .v-slider__thumb {
  border-color: #414141 !important;
}

.e-custom-slider-container .e-custom-slider-progress {
  position: absolute;
  top: .9em;
  width: calc(100% - 2em);
  left: 1em;
}

.e-custom-slider-lock-container {
  margin-top: 6px;
  margin-right: .3em;
}

.e-custom-slider-average-marker {
  position: relative;
  display: block;
  width: 6px;
  border-radius: 3px;
  border: 1px solid #605f5f;
  height: 140%;
  top: -20%;
}
</style>
